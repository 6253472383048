import React from "react";
import { IMeasureType, IMetricBatchEvaluationResult, IQuery, IQueryFilter } from "@src/types/ab-testing-exploration";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import dayjs from "dayjs";
import { config } from "@src/config";
import {
  ABExplorationKeyWords,
  FilterOperator,
  SupportedOrderDirection,
} from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { getMetricEvaluationRequireMeasures } from "@src/util/ab-testing/report-data/get-metric-evaluation-require-measures";
import { useAbExplorationEvaluateMetric } from "@src/hooks/ab-testing/use-ab-exploration-evaluate-metric";
import { ChartLayoutWithUserPercent } from "@src/pages/ab-test-explore/component/layout/chart-layout-with-user-percent";
import { ReportSummary } from "./report-summary";
import { ReportRaw } from "./report-raw";
import { ChartAction, ChartActionLayout } from "../../../../action";
import { toTitle } from "@src/helpers/text-helper";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { useGetMetricApplied } from "@src/hooks/fb-explore/metric-performance/use-get-metric-applied";

function getObjectMergedKey(r: any, keys: string[]) {
  return keys?.map((k) => r[k]).join("-") || "-";
}

export const ReportData: React.FC<{ targetKey: string; chartKey: string }> = ({ targetKey, chartKey }) => {
  const { form } = useMetricPerformanceContext();
  const {
    productCode,
    variants,
    maxDayDiff,
    explorationOptions: queryOptions,
    explorationFilters,
  } = useGetGeneralField({ form });
  const actDateRange = Form.useWatch(ChartFormFieldsEnum.METRIC_TIME_RANGE, form) ?? [];
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? {};

  const { targetModelAlias, measureValue, breakdownBy, filters, significanceLevel, measureOptions, numValidFields } =
    useGetMetricApplied({ targetKey });

  const isMetricConfigReady = numValidFields > 0;

  const baseJsonQuery = {
    productCode,
    modelAlias: targetModelAlias,
    options: queryOptions,
  };

  //start: get breakdown data
  const jsonQueryFilter: IQueryFilter[] =
    filters?.rules.map(({ field, operator, value }: { field: string; operator: string; value: string | string[] }) => {
      return {
        member: field,
        operator: operator === "=" ? "equals" : operator,
        values: typeof value === "string" ? [value] : value,
      };
    }) ?? [];

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.DAY_DIFF,
    operator: FilterOperator.BETWEEN,
    values: [0, maxDayDiff],
  });

  jsonQueryFilter.push({
    member: ABExplorationKeyWords.ACT_DATE,
    operator: FilterOperator.BETWEEN,
    values: actDateRange.map((item: string) => dayjs(item).format(config.DATE_FORMAT)),
  });

  jsonQueryFilter.push(...explorationFilters);

  const shouldLoad = actDateRange !== undefined && targetModelAlias !== undefined && measureValue !== undefined;

  const { measure_type: measureType, measure_definition: measureDefinition } = measureOptions;

  const { requiredMeasures, toEvaluatingData } = getMetricEvaluationRequireMeasures(
    measureValue,
    measureType,
    measureDefinition,
    true,
    true,
  );

  const detailQueryRequest = React.useMemo(() => {
    const detailJsonQuery: IQuery = {
      dimensions: new Array<string>(...new Set<string>([...(breakdownBy || []), ABExplorationKeyWords.VARIANT])),
      // measures: [ABExplorationKeyWords.SAMPLE_SIZE],
      measures: requiredMeasures,
      filters: [...jsonQueryFilter],
      limit: 10000,
      order: [[ABExplorationKeyWords.SAMPLE_SIZE, SupportedOrderDirection.DESCENDANT]],
    };
    return {
      ...baseJsonQuery,
      query: detailJsonQuery,
      isSkip: !isMetricConfigReady || !shouldLoad,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    JSON.stringify(shouldLoad),
    JSON.stringify(requiredMeasures),
    JSON.stringify(breakdownBy),
    JSON.stringify(jsonQueryFilter),
  ]);

  const {
    isLoading: isLoadingDetail,
    result: detailResult,
    elapsed,
    meta,
    renderedQuery,
  } = useLoadAbExploration(detailQueryRequest);

  const groupedDetailData = React.useMemo(() => {
    if (detailResult === undefined) {
      return {};
    }
    return Object.groupBy(detailResult, (i) => getObjectMergedKey(i, breakdownBy));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(detailResult)]);

  const evaluatingMetricRequest = React.useMemo(
    () => {
      const batchData = Object.keys(groupedDetailData)
        .filter((batchId: string) => {
          // only evaluate valid records
          // return validBreakdownRecords.findIndex((rec) => getObjectMergedKey(rec, breakdownBy) === batchId) !== -1
          const baseVariantReportIsMissing =
            groupedDetailData[batchId]?.findIndex(({ exp_group }) => exp_group === baseVariant) === -1;
          const isNotEnoughVariantReportToCompare =
            groupedDetailData[batchId] === undefined || groupedDetailData[batchId]?.length <= 1;

          return !isNotEnoughVariantReportToCompare && !baseVariantReportIsMissing;
        })
        .map((batchId: string) => {
          return {
            batch_id: batchId,
            data: groupedDetailData[batchId]
              ?.map(({ [ABExplorationKeyWords.VARIANT]: variantName, ...rest }) => {
                return toEvaluatingData(variantName, rest);
              })
              .filter((d) => !!d),
          };
        })
        .filter(({ data }) => !!data && data.length >= 2) // there is not enough data to evaluate
        .filter(({ data }) => data?.findIndex(({ exp_group }) => exp_group === baseVariant) !== -1); // invalid base report

      return {
        baseGroup: baseVariant as string,
        variantGroups: variants || [],
        metric: measureValue,
        metricType: measureType as IMeasureType,
        significanceLevel: significanceLevel as number,
        data: batchData,
        batch: true,
        isSkip: !isMetricConfigReady || !shouldLoad || isLoadingDetail,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(groupedDetailData)],
  );
  const { isLoading: isEvaluatingMetric, result: metricEvaluationResult } = useAbExplorationEvaluateMetric({
    ...evaluatingMetricRequest,
  });

  const { updateChartState } = useChartWrapperContext();

  const mergedDetailReport = React.useMemo(() => {
    if (metricEvaluationResult === undefined || detailResult === undefined) return [];
    const groupedDetailData = Object.groupBy(detailResult, (i) => getObjectMergedKey(i, breakdownBy));
    return Object.keys(groupedDetailData)
      .map((batchId) => {
        const metricEvaluationReport = metricEvaluationResult.find(
          (rec) => (rec as IMetricBatchEvaluationResult).batch_id === batchId,
        ) as IMetricBatchEvaluationResult;
        return (
          groupedDetailData[batchId]?.map((rec) => {
            const metricEvaluation =
              metricEvaluationReport?.data.find(
                ({ variant_group }) => variant_group === rec[ABExplorationKeyWords.VARIANT],
              ) || {};
            return {
              ...rec,
              ...metricEvaluation,
              // evaluation api return measure as 0 when error throws
              // then we need to use measures in record to reduce as much as loss
              ...toEvaluatingData(rec[ABExplorationKeyWords.VARIANT], rec),
            };
          }) || []
        );
      })
      .reduce((prev, curr) => [...prev, ...curr], []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(metricEvaluationResult)]);

  React.useEffect(() => {
    if (mergedDetailReport === undefined) return;
    updateChartState({
      chartKey,
      dataSource: mergedDetailReport,
      modelAlias: detailQueryRequest.modelAlias,
      queryOption: queryOptions,
      jsonQuery: detailQueryRequest.query,
      renderedQuery,
      meta,
      elapsed,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(mergedDetailReport), JSON.stringify(detailQueryRequest)]);

  return (
    <>
      {numValidFields === 0 ? (
        <></>
      ) : (
        <ChartLayoutWithUserPercent
          defaultMode="chart"
          hiddenMode={mergedDetailReport === undefined || mergedDetailReport.length === 0}
          moreActions={
            <div className="-translate-x-2">
              <ChartActionLayout>
                <ChartAction tabKey={chartKey} tabName={toTitle(chartKey)} form={form} />
              </ChartActionLayout>
            </div>
          }
          renderRaw={<ReportRaw chartKey={chartKey} />}
          renderChart={<ReportSummary targetKey={targetKey} chartKey={chartKey} />}
          loading={isLoadingDetail || isEvaluatingMetric}
        />
      )}
    </>
  );
};
