import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { config } from "@src/config";
import { useExploreContext } from "@src/contexts/explore-context";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { Form, FormInstance } from "antd";
import React from "react";

export const PlatformField: React.FC<{ form?: FormInstance<object> }> = ({ form }) => {
  const { isViewMode } = getModeOfLocation();
  const { updateQuery, query } = useExploreContext();

  const handleChange = (value: string[]) => {
    const _filter = (query?.filters ?? [])?.filter((item: any) => {
      return !!item?.and || (item.member && item.member !== config.APP_REPORT_AMA_ID);
    });
    updateQuery({
      filters: [
        {
          member: config.APP_REPORT_AMA_ID,
          operator: "equals",
          values: value,
        },
        ..._filter,
      ],
    });
  };
  const productCodeWatch = Form.useWatch("product_code", form);

  const options = React.useMemo(() => {
    return [
      {
        label: "Android",
        value: productCodeWatch + "a",
      },
      {
        label: "IOS",
        value: productCodeWatch + "i",
      },
    ];
  }, [productCodeWatch]);

  React.useEffect(() => {
    if (!productCodeWatch) return;

    form?.setFieldValue("platform", [`${productCodeWatch}a`]);
    handleChange([`${productCodeWatch}a`]);
  }, [productCodeWatch]);

  return (
    <Form.Item
      name="platform"
      label={<span className="font-bold">Platform</span>}
      className="w-[20%] mb-0 pr-2"
      rules={[
        {
          required: true,
          message: "Please select platform",
        },
      ]}
    >
      <SelectWithSpinAndCustomMaxTag
        mode="multiple"
        disabled={!!isViewMode || !productCodeWatch}
        placeholder="Platform"
        options={options}
        onChange={handleChange}
      />
    </Form.Item>
  );
};
