import { useAuth0 } from "@auth0/auth0-react";
import { AuthProvider } from "@refinedev/core";
import { config } from "@src/config";
import { shared } from "@src/util/shared";
import axios from "axios";
const axiosInstance = axios.create();

import React from "react";
const notAuthenticated = {
  authenticated: false,
  error: {
    message: "Authentication failed",
    name: "Token not found",
  },
  logout: true,
  redirectTo: "/login",
};
export const useAuth0Provider = () => {
  const [isLoadingAPI, setIsLoading] = React.useState(false);

  const { user, logout, getIdTokenClaims, isLoading, isAuthenticated } = useAuth0();
  // React.useEffect(() => {
  //   if (!isAuthenticated || isLoading) return;
  //   const keepUrl = localStorage.getItem("keep-url");
  //   if (keepUrl) {
  //     window.localStorage.removeItem("keep-url");
  //     window.location.href = keepUrl;
  //   }
  // }, [isAuthenticated, isLoading]);

  const getPermissions = async (token: string) => {
    if (!token) return notAuthenticated;
    setIsLoading(true);
    const data = await axiosInstance.get(`${config.NESTJS_URL}/api/permissions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    const { permissions, roles, superAdmins } = data?.data ?? {};
    shared.set("permissions", permissions);
    shared.set("roles", roles);
    shared.set("superAdmins", superAdmins);
    if (user) {
      shared.set("user", user);
    }
    setIsLoading(false);
  };

  const auth0Provider: AuthProvider = {
    login: async () => {
      return {
        success: true,
      };
    },
    logout: async () => {
      logout({
        openUrl() {
          window.location.replace(window.location.origin);
        },
      });
      return {
        success: true,
      };
    },
    onError: async (error) => {
      console.error(error);
      return { error };
    },
    check: async () => {
      try {
        const token = await getIdTokenClaims();
        if (token) {
          axios.defaults.headers.common = {
            Authorization: `Bearer ${token.__raw}`,
            "x-sat-user-token": `Bearer ${token.__raw}`,
          };
          await getPermissions(token.__raw);
          return {
            authenticated: true,
          };
        } else {
          return {
            authenticated: false,
            error: {
              message: "Check failed",
              name: "Token not found",
            },
            redirectTo: "/login",
            logout: true,
          };
        }
      } catch (error: any) {
        return {
          authenticated: false,
          error: new Error(error),
          redirectTo: "/login",
          logout: true,
        };
      }
    },
    getPermissions: async () => null,
    getIdentity: async () => {
      if (user) {
        return {
          ...user,
          avatar: user.picture,
        };
      }
      return null;
    },
  };

  getIdTokenClaims().then((token) => {
    if (token) {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.__raw}`,
        "x-sat-user-token": `Bearer ${token.__raw}`,
      };
    }
  });

  return { auth0Provider, isLoading, axios };
};
