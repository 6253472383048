import { Empty, Table, Tag } from "antd";
import React from "react";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";
import { ABExplorationKeyWords } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { toTitle } from "@src/helpers/text-helper";
import { css } from "@emotion/react";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ControlReport, VariantReport } from "./variant-report";
import { useGetMetricApplied } from "@src/hooks/fb-explore/metric-performance/use-get-metric-applied";

function getObjectMergedKey(r: any, keys?: string[]) {
  return keys?.map((k) => r[k]).join("-") || "";
}

export const ReportSummary: React.FC<{
  targetKey: string;
  chartKey: string;
  loading?: boolean;
  customTableProps?: any;
}> = ({ targetKey, chartKey, loading = false, customTableProps }) => {
  const {
    dataSources: { [chartKey]: rawData },
  } = useChartWrapperContext();
  const { form } = useMetricPerformanceContext();
  const { variants, variants_options } = useGetGeneralField({ form });
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? {};
  const { significanceLevel, breakdownBy } = useGetMetricApplied({ targetKey });

  const mergedDetailReport = React.useMemo(() => {
    if (rawData === undefined) return [];
    const groupedData = Object.groupBy(rawData, (i) => getObjectMergedKey(i, breakdownBy));

    return Object.keys(groupedData).map((batchId) => {
      const records: any[] = groupedData[batchId] || [];
      return {
        batchId,
        batchIdentifiers:
          breakdownBy
            ?.map((k: any) => {
              if (records === undefined || records.length === 0) return {};

              return {
                [k]: records[0][k],
              };
            })
            .reduce((prev: any, curr: any) => Object.assign(prev, curr), {}) || {},
        // variants: records
        variants: records
          .map((rec) => {
            return {
              [rec[ABExplorationKeyWords.VARIANT]]: {
                ...rec,
              },
            };
          })
          .reduce((prev, curr) => Object.assign(prev, curr)),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(rawData)]);

  const detailTableColumns = React.useMemo(() => {
    const _detailTableColumns = [];
    if (breakdownBy !== undefined && breakdownBy.length > 0) {
      _detailTableColumns.push({
        title: "",
        children: [
          ...breakdownBy.map((v: string) => {
            return {
              title: toTitle(v),
              width: 120,
              fixed: "left",
              dataIndex: ["batchIdentifiers", v],
              render: (value: any[]) => {
                return <div className="pl-[8px]">{value ? value : <Tag>NULL</Tag>}</div>;
              },
              filters: mergedDetailReport.map(({ batchIdentifiers }) => {
                const { [v]: distinctValue } = batchIdentifiers;
                return {
                  text: distinctValue ? distinctValue : "NULL",
                  value: distinctValue,
                };
              }),
              onFilter: (value: any, record: any) => {
                return record["batchIdentifiers"][v] !== null
                  ? record["batchIdentifiers"][v].indexOf(value) === 0
                  : record["batchIdentifiers"][v] === value;
              },
              sorter: (a, b) => {
                const v1 = (a["batchIdentifiers"][v] || "").toString();
                const v2 = (b["batchIdentifiers"][v] || "").toString();
                return v1.localeCompare(v2);
              },
            };
          }),
        ],
      });
    }
    _detailTableColumns.push({
      title: "Variants",
      children: [
        ...(variants
          ?.sort((a: string, b: string) => (a === baseVariant ? -1 : b === baseVariant ? 1 : 0))
          .map((v: string) => {
            const { label: groupAlias } = variants_options?.find(({ value }) => value === v) || {};
            return {
              title: groupAlias,
              key: v,
              width: 200,

              dataIndex: ["variants", v],
              render: (value: any) => {
                return v === baseVariant ? (
                  <ControlReport groupName={v} groupAlias={groupAlias || ""} report={value} />
                ) : (
                  <VariantReport
                    groupName={v}
                    groupAlias={groupAlias || ""}
                    report={value}
                    isSignificantFn={(pValue) => {
                      return pValue !== null && pValue !== undefined ? pValue < significanceLevel : false;
                    }}
                    colorFn={(pValue, upliftPercent) => {
                      const color = {
                        bg: "bg-neutral-100",
                        upliftPercentTextColor: "text-slate-900",
                      };
                      if (pValue === undefined || !upliftPercent) {
                        return color;
                      }

                      if (pValue < significanceLevel) {
                        color.bg = upliftPercent > 0 ? "bg-green-100" : "bg-red-100";
                        color.upliftPercentTextColor = upliftPercent > 0 ? "text-green-500" : "text-red-500";
                      }
                      return color;
                    }}
                  />
                );
              },
            };
          }) || []),
      ],
    });
    return _detailTableColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variants, breakdownBy]);

  return mergedDetailReport === undefined || mergedDetailReport.length === 0 ? (
    <Empty description={"No data"} />
  ) : (
    <Table
      rowKey="batchId"
      sticky={{
        offsetHeader: 0,
      }}
      columns={detailTableColumns}
      dataSource={mergedDetailReport}
      bordered
      size="middle"
      pagination={{
        pageSize: 50,
        hideOnSinglePage: true,
      }}
      css={css`
        /* .ant-table-tbody{
                padding: 0 !important;
            } */
        .ant-table-tbody > tr > td {
          padding: 0 !important;
        }
      `}
      scroll={{ x: "max-content" }}
      loading={loading}
      {...customTableProps}
    />
  );
};
