import { DatabaseOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Badge, Button, Divider, Form, Input, Modal, Popover, TreeSelect } from "antd";
import React from "react";
import { css } from "@emotion/react";
import { useTreeSelectDimension } from "@src/hooks/ab-testing/metric-performance/use-tree-select-dimenson";

type BreakdownModalProps = {
  targetKey: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const MetricBreakDown: React.FC<BreakdownModalProps> = ({ targetKey, open, setOpen }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();
  const metricBreakdownWatch = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], form);

  const {
    valueSelected,
    treeSelectProps,
    addItemProps: { badgeProps, isHasTemplate, inputProps, buttonProps },
  } = useTreeSelectDimension({
    multiple: true,
    optionsProps: {
      initValues: form?.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]) ?? [],
      syncValueWithForm: {
        enabled: true,
        fieldName: [targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN],
      },
    },
  });

  const handleOk = () => {
    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN], valueSelected);
    handleUpdateMetricTabItems(targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN);
    setOpen(false);
  };

  return (
    <div className="flex justify-center">
      <div
        className="flex items-center cursor-pointer hover:text-blue-600 text-gray-500 canEdit"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DatabaseOutlined className="mr-2" />
        Breakdown ({metricBreakdownWatch?.length ?? 0})
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} onOk={handleOk} width={400} title="Metric breakdown" centered>
        <TreeSelect
          {...treeSelectProps}
          placeholder="Please select"
          dropdownRender={(originNode: any) => {
            return (
              <div>
                {originNode}
                {isHasTemplate && (
                  <>
                    <Divider className="my-1" />
                    <div className="flex justify-between px-2 pb-1">
                      <Input
                        {...inputProps}
                        css={css`
                          .ant-input-prefix {
                            margin-right: 0px !important;
                          }
                        `}
                        prefix={<Badge {...badgeProps} />}
                        suffix={
                          <Popover
                            content={
                              <div className="max-w-[400px]">
                                <p className="m-0">
                                  Add event param (ep) to break measure with desired params dimension. Input must match
                                  the template:
                                </p>
                                <span className="text-gray-500 italic">Ex: ep_difficulty_tag</span>
                              </div>
                            }
                            title="Add event param"
                            trigger="hover"
                          >
                            <ExclamationCircleOutlined />
                          </Popover>
                        }
                        className="mr-4"
                      />
                      <Button type="primary" {...buttonProps}>
                        <PlusOutlined />
                        Add item
                      </Button>
                    </div>
                  </>
                )}
              </div>
            );
          }}
        />
      </Modal>
      <Form.Item name={[targetKey, ChartFormFieldsEnum.METRIC_BREAKDOWN]} hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
