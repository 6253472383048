import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";

type RawDataTableProps = {
  rowKey: string;
  dataSource: any[];
  columns: ColumnsType<any>;
};

export const RawDataTable: React.FC<RawDataTableProps> = ({ rowKey, columns, dataSource }) => {
  return (
    <Table
      rowKey={rowKey}
      dataSource={dataSource}
      columns={columns as any[]}
      scroll={{ y: 500, x: 400 }}
      sticky={{ offsetHeader: 0 }}
      bordered
      pagination={{
        pageSizeOptions: [20, 50, 100, 200, 500],
        defaultPageSize: 20,
      }}
    />
  );
};
