import { ImportOutlined, InboxOutlined } from "@ant-design/icons";
import { useModal } from "@refinedev/antd";
import { Button, Modal, Tabs, Upload } from "antd";
import CodeEditor from "@uiw/react-textarea-code-editor";
import React from "react";
const { Dragger } = Upload;
interface Props {
  setData: (data: object) => void;
}

export const ImportProduct: React.FC<Props> = (props) => {
  const {
    modalProps: { visible, ..._modalProps },
    show,
    close,
  } = useModal();
  const [pData, setPData] = React.useState<string | undefined>();
  const items = [
    {
      label: "Upload file from local",
      key: "uploadFile",
      children: <UploadFile setData={setPData} />,
    },
    {
      label: "JSON",
      key: "json",
      children: <JSONEditor setData={setPData} />,
    },
  ];
  return (
    <div>
      <Button icon={<ImportOutlined />} onClick={show} children={"Import"} />
      <Modal
        {..._modalProps}
        onOk={() => {
          close();
          pData && props.setData(JSON.parse(pData || "{}"));
        }}
        destroyOnClose
        centered
      >
        <Tabs items={items} />
      </Modal>
    </div>
  );
};

interface TabChildrenProps {
  setData: (data: string) => void;
}

const UploadFile: React.FC<TabChildrenProps> = ({ setData }) => {
  const convertFileToJson = (file: any) => {
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = (e) => {
      const res = (e.target?.result || "") as string;
      setData(res);
    };
  };
  return (
    <div className="w-full ">
      <Dragger
        accept=".json"
        maxCount={1}
        height={200}
        className=""
        onChange={(info) => {
          if (info.fileList.length === 0) return;
          convertFileToJson(info.file);
        }}
        beforeUpload={() => {
          return false;
        }}
        listType="text"
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
      </Dragger>
    </div>
  );
};

const JSONEditor: React.FC<TabChildrenProps> = ({ setData }) => {
  return (
    <div className="w-full h-[500px]">
      <CodeEditor
        className="w-full  h-full"
        language="json"
        placeholder="Please enter JSON code."
        onChange={(e) => {
          setData(e.target.value);
        }}
        padding={15}
        style={{
          fontSize: 12,
          backgroundColor: "#f5f5f5",
          fontFamily: "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
          overflow: "scroll",
        }}
      />
    </div>
  );
};
