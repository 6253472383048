import { useNavigation } from "@refinedev/core";
import { Button, Result } from "antd";

export const Result403: React.FC = () => {
  const { push } = useNavigation();
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button
          type="primary"
          onClick={() => {
            push("/");
          }}
        >
          Back Home
        </Button>
      }
    />
  );
};
