import { flattenFilters } from "@cubejs-client/core";
import { useNavigation, useOne, useParsed } from "@refinedev/core";
import { config, PAGE_URL } from "@src/config";
import { mappingExpTypeToLabel } from "@src/constant/explore/mapping-title-new-explore";
import { isArrayAndNotEmpty } from "@src/util/common/array";
import { convertToDateRangeValue } from "@src/util/fb-exploration/convert-to-date-range-value";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import dayjs from "dayjs";
import React from "react";
import { useMe } from "./use-me";

const validateVizState = (vizState: Record<string, any>) => {
  const result: Record<string, any> = {};
  Object.keys(vizState).forEach((key) => {
    let isMatch = isArrayAndNotEmpty(vizState[key]);
    if (key === "filters") {
      isMatch = isArrayAndNotEmpty(flattenFilters(vizState[key] || []));
      if (!isMatch) {
        result[key] = [{ and: [] }];
      }
    }
    if (key === "limit") isMatch = !!vizState[key];

    if (isMatch) {
      result[key] = vizState[key];
    }
  });
  return result;
};

const formatExploration = (exploration: Record<string, any>) => {
  const time =
    exploration?.time_dimensions?.find((t: any) => t.dimension === config.APP_REPORT_DATE)?.dateRange ?? "Last 7 days";
  return {
    id: exploration.id,
    name: exploration.title,
    description: exploration.description ?? "",
    platform: exploration.config.filters?.find((f: any) => f.member === config.APP_REPORT_AMA_ID)?.values ?? [],
    // "time-range": Array.isArray(time) ? "custom" : time,
    "time-range": "custom",
    "custom-time-range": Array.isArray(time) ? time?.map((t) => dayjs(t)) : convertToDateRangeValue(time),
    vizState: {
      chartType: exploration.config.chart_type,
      query: validateVizState({
        measures: exploration.config.measures,
        dimensions: exploration.config.dimensions,
        timeDimensions:
          exploration.config.time_dimensions?.length > 0
            ? exploration.config.time_dimensions
            : [
                {
                  dimension: config.APP_REPORT_DATE,
                  dateRange: "Last 7 days",
                },
              ],
        filters: exploration.config.filters,
        segments: exploration.config.segments,
        order: exploration.config.order,
      }),
      pivotConfig: exploration.config.pivot_config,
      type: mappingExpTypeToLabel[exploration.config.exp_type],
      product_code: exploration.product.code,
    },
  };
};
export const useGetExplorations = (resetForm?: () => void) => {
  const { isSuperAdmin, user } = useMe();
  const { isEditMode } = getModeOfLocation();
  const { replace } = useNavigation();
  const { params, id } = useParsed();
  const product_code = params?.product_code;
  const { data, isLoading, error } = useOne({
    id,
    resource: `explorations`,
    dataProviderName: "nestjsx",
    meta: {
      join: [
        {
          field: "product",
        },
      ],
      headers: {
        "Product-Code": product_code,
      },
    },

    queryOptions: {
      // staleTime: 2 * 60 * 1000,
      networkMode: "always",
      enabled: Boolean(user) && Boolean(id) && Boolean(product_code),
      onSuccess: (data) => {
        resetForm?.();
        if (isSuperAdmin) return;
        const owner = data?.data?.owner;
        const isOwnerOfConfig = owner === user?.email;
        if (isEditMode && !isOwnerOfConfig) {
          replace(PAGE_URL.ERROR_403);
        }
      },
    },
    errorNotification: false,
  });

  const exploration = data?.data ? formatExploration(data?.data) : null;
  return {
    exploration,
    isLoading,
    isFetching: false,
    owner: data?.data?.owner,
    error,
  };
};
