import { HttpError, useCustomMutation } from "@refinedev/core";
import { useState, useEffect } from "react";
import { config } from "@src/config";
import {
  IMeasureType,
  IMetricBatchEvaluationResponse,
  IMetricBatchEvaluationResult,
  IMetricEvaluateRequest,
  IMetricEvaluationResponse,
  IMetricEvaluationResult,
} from "@src/types/ab-testing-exploration";
import { SupportedMeasureType } from "@src/constant/ab-testing/ad-testing-exploration.enum";

const getEvaluateEndpoint = (type?: IMeasureType) => {
  // fallback to average test if not match
  switch (type) {
    case SupportedMeasureType.MEAN:
      return "average-test";
    case SupportedMeasureType.PROPORTION:
      return "proportion-test";
    case SupportedMeasureType.RATIO:
      return "ratio-test";
    default:
      return "average-test";
  }
};

type AbExplorationEvaluateMetricProps = {
  baseGroup: string;
  variantGroups: string[];
  metric: string;
  metricType?: IMeasureType;
  significanceLevel: number;
  data: any;
  refineOptions?: {
    dataProviderName?: string;
  };
  batch?: boolean;
  isSkip?: boolean;
};

export const useAbExplorationEvaluateMetric = ({
  baseGroup,
  variantGroups,
  metric,
  metricType,
  significanceLevel,
  data,
  refineOptions,
  batch = false,
  isSkip = false,
}: AbExplorationEvaluateMetricProps) => {
  const [result, setResult] = useState<(IMetricEvaluationResult | IMetricBatchEvaluationResult)[]>();
  const requestPayload: IMetricEvaluateRequest = {
    base_group: baseGroup,
    variant_groups: variantGroups,
    metric,
    significance_level: significanceLevel,
    data,
  };
  const evaluateEndpoint = getEvaluateEndpoint(metricType);
  const url = `${config.BACKEND_URL}/abtest/${evaluateEndpoint}${batch ? "/batch" : ""}`;

  const { mutateAsync, isLoading, error } = useCustomMutation<
    IMetricEvaluationResponse | IMetricBatchEvaluationResponse,
    HttpError
  >({
    mutationOptions: {
      onSettled(data, error) {
        if (error) {
          return;
        }
        setResult(data?.data?.data ?? []);
      },
    },
  });

  async function fetch() {
    mutateAsync({
      ...refineOptions,
      // url: `${config.BACKEND_URL}/abtest/${evaluateEndpoint}`,
      url,
      method: "post",
      values: requestPayload,
    });
  }

  useEffect(() => {
    if (!isSkip) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(requestPayload)]);

  return {
    isLoading,
    result,
    httpError: error,
    refetch: fetch,
  };
};
