import { useNavigation, useParsed, useResource } from "@refinedev/core";
import { useMe } from "@src/hooks/use-me";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getParamsFieldValue } from "@src/util/ab-testing/params";
import { shared } from "@src/util/shared";
import { Button, Result } from "antd";
import { PropsWithChildren } from "react";

export const ResourceAuth: React.FC<PropsWithChildren> = ({ children }) => {
  const { push } = useNavigation();

  const { roles, permissions, user, isSuperAdmin } = useMe();
  const { params } = useParsed();
  const abProductCode = getParamsFieldValue(FilterFormFieldsEnum.PRODUCT_CODE, "filterForm");
  const productCode = params?.product_code || abProductCode;

  const myRole = roles.find((role: any) => role.productCode === productCode)?.role;
  const { resource: _resource, action: _action } = useResource();
  const resource = _resource?.meta?.resourceOverride || _resource?.meta?.parent;
  const action = _resource?.meta?.actionOverride || _action;

  if (productCode && !isSuperAdmin && (!myRole || !permissions[myRole][resource || ""]?.includes(action))) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Button
            type="primary"
            onClick={() => {
              push("/");
            }}
          >
            Back Home
          </Button>
        }
      />
    );
  }

  return <div>{children}</div>;
};
