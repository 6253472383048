import dataProviderNestJsx, { handleJoin, transformHttpError } from "@refinedev/nestjsx-crud";
import { AxiosInstance } from "axios";
import type { DataProvider } from "@refinedev/core";
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export const nestjsxProvider = (apiUrl: string, httpClient: AxiosInstance): Required<DataProvider> => ({
  ...dataProviderNestJsx(apiUrl, httpClient),
  update: async ({ resource, id, variables, meta }) => {
    const headers = meta?.headers ?? {};
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables, { headers });

    return {
      data,
    };
  },
  create: async ({ resource, variables, meta }) => {
    const url = `${apiUrl}/${resource}`;
    const headers = meta?.headers ?? {};

    try {
      const { data } = await httpClient.post(url, variables, { headers });

      return {
        data,
      };
    } catch (error) {
      const httpError = transformHttpError(error);
      throw httpError;
    }
  },
  deleteOne: async ({ resource, id, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    const headers = meta?.headers ?? {};

    const { data } = await httpClient.delete(url, { headers });

    return {
      data,
    };
  },
  getOne: async ({ resource, id, meta }) => {
    const url = `${apiUrl}/${resource}/${id}`;
    let query = RequestQueryBuilder.create();

    query = handleJoin(query, meta?.join);
    const headers = meta?.headers ?? {};
    const { data } = await httpClient.get(`${url}?${query.query()}`, { headers });

    return {
      data,
    };
  },
});
