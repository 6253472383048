import { useNavigation } from "@refinedev/core";
import React, { useEffect, useState } from "react";
import { config } from "../../config";
import { useLocalStorage } from "usehooks-ts";
import { LoadingFullScreenWithText } from "@src/components/loading/loading-full-screen-with-text";
import { useQuery } from "@src/hooks/use-query";

export interface Tokens {
  accessToken: string;
  refreshToken: string;
  auth0Token: string;
}

const LoginRedirect = () => {
  const [text, setText] = useState("Loading...");
  const navigate = useNavigation();
  const query = useQuery();
  const accessToken = query.get("token");
  const tokenRaw = query.get("tokenRaw");
  const refreshToken = query.get("refreshToken");
  const [, setToken] = useLocalStorage<Tokens | undefined>(config.TOKEN_KEY, undefined);

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search
    // localStorage.setItem(config.TOKEN_KEY, location.search);
    if (accessToken && refreshToken && tokenRaw) {
      setToken({
        accessToken,
        refreshToken,
        auth0Token: tokenRaw,
      });
      setText("You have been successfully logged in. You will be redirected in a few seconds...");

      setTimeout(() => {
        const keepUrl = localStorage.getItem("keep-url");

        if (keepUrl) {
          window.localStorage.removeItem("keep-url");
          window.location.href = keepUrl;
          return;
        }
        navigate.push("/");
      }, 3000); // Redirect to homepage after 3 sec
    } else {
      setText("An error occurred, please see the developer console.");
    }
  }, [accessToken, refreshToken, tokenRaw]);

  return <LoadingFullScreenWithText text={text} />;
};

export default LoginRedirect;
