import { CanParams, CanReturnType } from "@refinedev/core";
import { useMe } from "@src/hooks/use-me";

export const can = async ({ resource, action, params }: CanParams): Promise<CanReturnType> => {
  const { roles, permissions, isSuperAdmin } = useMe();

  const productCode = params?.productCode;
  const currentRole = roles.find((role: any) => role.productCode === productCode)?.role;
  const resourcesAdmin = ["permission", "product"];
  if (isSuperAdmin) {
    return {
      can: true,
    };
  }
  if (resource && resourcesAdmin.includes(resource)) {
    return {
      can: false,
    };
  }
  if (action === "list") {
    return { can: true };
  }

  if (resource && currentRole) {
    const can = permissions[currentRole][resource]?.includes(action);

    return { can, reason: !can ? `You don't have permission to perform this action` : "" };
  }
  return {
    can: false,
    reason: `You don't have permission to perform this action`,
  };
};
