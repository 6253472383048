import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { IMemberOptions, IMemberRationOptions } from "@src/types/ab-testing-exploration";
import { Form } from "antd";

export const useGetMetricApplied = ({ targetKey }: { targetKey: string }) => {
  const { form } = useMetricPerformanceContext();
  const metricApplied =
    Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_APPLIED], form) ??
    form.getFieldValue(targetKey)?.[ChartFormFieldsEnum.METRIC_APPLIED] ??
    {};
  const {
    [ChartFormFieldsEnum.METRIC_TARGET_MODEL]: targetModelAlias,
    [ChartFormFieldsEnum.METRIC_MEASURE]: measureName,
    [ChartFormFieldsEnum.METRIC_MEASURE_VALUE]: measureValue,
    [ChartFormFieldsEnum.METRIC_MEASURE_TYPE]: measureType,
    [ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]: measureOptions,
    [ChartFormFieldsEnum.METRIC_SIGNIFICANCE_LEVEL]: significanceLevel,
    [ChartFormFieldsEnum.METRIC_BREAKDOWN]: breakdownBy,
    [ChartFormFieldsEnum.METRIC_FILTER]: filters,
  } = metricApplied;

  return {
    targetModelAlias,
    measureName,
    measureValue,
    measureType,
    measureOptions: JSON.parse(measureOptions ?? "{}") as IMemberRationOptions | IMemberOptions,
    significanceLevel,
    breakdownBy,
    filters,
    numValidFields: Object.keys(metricApplied).length,
  };
};
