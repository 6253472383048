import { Portal } from "@src/components/portal";
import { Button, Popconfirm, Tooltip } from "antd";
import { SaveButton } from "@refinedev/antd";
import { CopyOutlined } from "@ant-design/icons";
import React from "react";
import { useDuplicateFirebaseExplore } from "@src/hooks/fb-explore/use-duplicate-firebase-explore";
import { ChartResultVisualization } from "@src/components/visualization/chart-result/chart-result-visualization";
import { FormInstance } from "antd/lib/form";
import { CanAccess } from "@refinedev/core";

export const ChartResultView: React.FC<{ exploration?: any; form?: FormInstance<any> }> = ({ exploration, form }) => {
  const layout = {
    ...exploration,
    vizState: {
      ...exploration.vizState,
    },
  };

  const { handleDuplicate } = useDuplicateFirebaseExplore({
    id: exploration.id,
    form,
  });

  const onDuplicate = () => {
    handleDuplicate({});
  };

  return (
    <div className="h-full ">
      <ChartResultVisualization mode="edit" layout={layout} pivotConfig={exploration?.vizState?.pivotConfig} />
      <Portal containerId="actions-wrapper">
        <div className="absolute bottom-2 right-2 flex space-x-2">
          <CanAccess
            resource="exploration"
            action="duplicate"
            params={{
              productCode: exploration?.vizState?.product_code,
            }}
          >
            <Tooltip title="Duplicate">
              <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={onDuplicate}>
                <Button className="" icon={<CopyOutlined />}>
                  Duplicate
                </Button>
              </Popconfirm>
            </Tooltip>
          </CanAccess>

          <SaveButton disabled htmlType="submit" className=" ">
            Save
          </SaveButton>
        </div>
      </Portal>
    </div>
  );
};
