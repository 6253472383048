import { Button, message, Table, Tooltip } from "antd";
import React from "react";

import type { TableProps } from "antd";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { convertArrayToCSV } from "@src/util/convert-array-to-csv";
import { CopyOutlined } from "@ant-design/icons";

export const ReportRaw: React.FC<{
  chartKey: string;
  loading?: boolean;
  customTableProps?: any;
}> = ({ chartKey, loading = false, customTableProps }) => {
  const {
    dataSources: { [chartKey]: mergedDetailReport },
  } = useChartWrapperContext();

  let tableColumns: TableProps["columns"] = [];

  if (mergedDetailReport !== undefined && mergedDetailReport.length > 0) {
    tableColumns = Object.keys(mergedDetailReport[0]).map((k, idx) => {
      return {
        key: idx,
        title: k,
        dataIndex: k,
        width: k.length * 15,
        filters: [...new Set(mergedDetailReport.map((i: any) => i[k]))].map((v) => {
          return {
            text: v ? v : "NULL",
            value: v,
          };
        }),
        onFilter: (value: any, record: any) => {
          return record[k] !== null ? record[k].indexOf(value) === 0 : record[k] === value;
        },
        sorter: (a, b) => {
          const v1 = (a[k] || "").toString();
          const v2 = (b[k] || "").toString();
          return v1.localeCompare(v2);
        },
      };
    });
  }

  const [messageApi, contextHolder] = message.useMessage();
  return (
    <>
      {contextHolder}
      <div className="flex justify-end">
        <Tooltip title="Copy data">
          <Button
            className="-translate-y-8 translate-x-4"
            onClick={() => {
              const dataCopy = convertArrayToCSV(mergedDetailReport);
              navigator.clipboard.writeText(dataCopy);
              messageApi.open({
                type: "success",
                content: "Copy success!",
              });
            }}
            type="text"
            style={{ color: "#1677ff" }}
            icon={<CopyOutlined />}
          />
        </Tooltip>
      </div>
      <Table
        id="idx"
        rowKey="idx"
        sticky={{
          offsetHeader: 0,
        }}
        columns={tableColumns}
        dataSource={mergedDetailReport}
        pagination={{
          hideOnSinglePage: true,
        }}
        bordered
        size="middle"
        scroll={{ x: "max-content" }}
        loading={loading}
        {...customTableProps}
      />
    </>
  );
};
