import { useApiUrl, useCustom } from "@refinedev/core";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { useGetOptionExtraField } from "@src/hooks/ab-testing/use-get-option-extra-field";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { SelectProps } from "antd";
import { ValueEditorProps } from "react-querybuilder";

const extraField = [
  "country_code",
  "media_source",
  "device_category",
  "device_model",
  "first_app_version",
  "last_app_version",
];
const fieldNameMapping: Record<string, FilterFormFieldsEnum> = {
  country_code: FilterFormFieldsEnum.EXTRA_COUNTRY,
  media_source: FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE,
  device_category: FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY,
  device_model: FilterFormFieldsEnum.EXTRA_DEVICE_MODAL,
  first_app_version: FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION,
  last_app_version: FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION,
};

const BaseSelectValueEditor: React.FC<{
  props: ValueEditorProps;
  extraProps?: SelectProps<any, any>;
}> = ({ props, extraProps }) => {
  const { handleOnChange, value } = props;
  if (extraProps?.options?.length === 0) {
    return null;
  }
  return (
    <SelectWithSpinAndCustomMaxTag
      {...(Boolean(value) && { value: Array.isArray(value) ? value : [value] })}
      style={{ width: 500 }}
      mode="tags"
      placeholder="Please input value"
      onChange={(_value) => {
        handleOnChange(_value);
      }}
      {...extraProps}
    />
  );
};
export const CustomValueEditor = (props: ValueEditorProps) => {
  const { field } = props;
  if (extraField.includes(field)) {
    return <ExtraFieldValueEditor {...props} />;
  }
  if (field === "event_name") {
    return <EventNameValueEditor {...props} />;
  }
  return <BaseSelectValueEditor props={props} />;
};

const ExtraFieldValueEditor = (props: ValueEditorProps) => {
  const { field } = props;
  const { filterForm: form } = useABContext();

  const { isLoading, options } = useGetOptionExtraField({
    dimension: field,
    fieldName: fieldNameMapping[field],
    form,
  });
  return (
    <BaseSelectValueEditor
      props={props}
      extraProps={{
        options,
        loading: isLoading,
      }}
    />
  );
};

const EventNameValueEditor = (props: ValueEditorProps) => {
  const { productCode } = useGetGeneralField({});
  const apiUrl = useApiUrl("nestjsx");
  const { data, isLoading } = useCustom({
    method: "get",
    dataProviderName: "nestjsx",
    url: `${apiUrl}/products/event-name/${productCode}`,
    queryOptions: {
      enabled: Boolean(productCode),
    },
  });
  return (
    <BaseSelectValueEditor
      props={props}
      extraProps={{
        options: data?.data?.map((item: any) => ({ label: item?.event_name, value: item.event_name })) ?? [],
        loading: isLoading,
      }}
    />
  );
};
