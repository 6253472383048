import { Card, Form, FormInstance, Input } from "antd";
import { ChartFormFieldsEnum } from "../../enum";
import { UserOverviewMemo } from "./user-overview";
import React from "react";
import { css } from "@emotion/react";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";
import { MetricPerformance } from "./metric-performance";
import { extractRatioParts } from "@src/util/extract-ratio-parts";
import { SupportedMeasureType } from "@src/constant/ab-testing/ad-testing-exploration.enum";

const ShowChartResult: React.FC<{ form: FormInstance }> = ({ form }) => {
  const extractMeasureDetail = (measureType: string, measureValue: string, regex?: RegExp) => {
    if (measureType === SupportedMeasureType.RATIO) {
      const matched = extractRatioParts(measureValue, regex) ?? {
        numerator: "",
        denominator: "",
      };

      return {
        [ChartFormFieldsEnum.NUMERATOR]: matched.numerator,
        [ChartFormFieldsEnum.DENOMINATOR]: matched.denominator,
      };
    }
    return {};
  };

  const getInitValuesChart = () => {
    const formValues = getGroupFieldValue("chartForm") ?? {};
    const metricTabItems = formValues?.[ChartFormFieldsEnum.METRIC_TAB_ITEMS] ?? [];

    const metricPerformanceDetail = metricTabItems.reduce((acc: Record<string, any>, item: Record<string, any>) => {
      const matchedRegex = JSON.parse(item?.[ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS] ?? "{}")?.matched_regex;
      const extractedMeasureDetail = extractMeasureDetail(
        item[ChartFormFieldsEnum.METRIC_MEASURE_TYPE],
        item[ChartFormFieldsEnum.METRIC_MEASURE_VALUE],
        matchedRegex,
      );
      acc[item.key] = {
        ...item,
        ...extractedMeasureDetail,
      };
      return acc;
    }, {});

    const mappingWithDefault = [
      {
        key: ChartFormFieldsEnum.CUSTOM_TIME_RANGE,
        value: formValues?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) => dayjs(item)),
      },
      {
        key: ChartFormFieldsEnum.METRIC_TIME_RANGE,
        value: formValues?.[ChartFormFieldsEnum.METRIC_TIME_RANGE]?.map((item: string) => dayjs(item)),
      },
    ];

    const dataWithDefault: Record<string, any> = {};

    mappingWithDefault.forEach(({ key, value }) => {
      dataWithDefault[key] = value;
    });

    return {
      ...formValues,
      ...metricPerformanceDetail,
      ...dataWithDefault,
    };
  };

  return (
    <Form name={FORM_NAME.CHART_FORM} form={form} initialValues={getInitValuesChart()}>
      <UserOverviewMemo form={form} />

      <Card
        css={css`
          .ant-tabs-tab-active {
            background-color: white !important;
          }
        `}
      >
        <MetricPerformance form={form} />
      </Card>
      <Form.Item name={ChartFormFieldsEnum.FILTER_FIELDS} hidden>
        <Input />
      </Form.Item>
    </Form>
  );
};
export const ShowChartResultMemo = React.memo(ShowChartResult);
