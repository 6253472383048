import { useCan } from "@refinedev/core";
import { useMe } from "@src/hooks/use-me";
import { shared } from "@src/util/shared";
import { Tooltip, Typography } from "antd";
import { Link } from "react-router-dom";

export const TitleColumn: React.FC<{ value?: string; record: any }> = ({ value, record }) => {
  const { isSuperAdmin } = useMe();
  const productCode = record.product_code;
  const { data: dataCanEdit } = useCan({
    resource: "exploration",
    action: "edit",
    params: {
      productCode: productCode,
    },
  });
  const canEdit = dataCanEdit?.can;
  const link =
    isSuperAdmin || canEdit
      ? `/explorations/firebase/edit/${productCode}/${record?.id}`
      : `/explorations/firebase/show/${productCode}/${record?.id}`;
  return (
    <Tooltip destroyTooltipOnHide title={value} className="w-[200px]" placement="topLeft">
      <Typography.Paragraph
        onClick={(e) => e.stopPropagation()}
        ellipsis={{
          rows: 2,
          expandable: false,
        }}
      >
        <Link to={link}>{value || "-"}</Link>
      </Typography.Paragraph>
    </Tooltip>
  );
};
