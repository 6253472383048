import React, { PropsWithChildren } from "react";
import { createContext, useContext } from "react";
import { QueryBuilder, QueryBuilderRenderProps, QueryBuilderState } from "@cubejs-client/react";
import cubejs, { CubeApi, DryRunResponse, Query } from "@cubejs-client/core";
import { useCustom, useParsed } from "@refinedev/core";
import { config } from "@src/config";
import { IProduct } from "@src/types/user";
import { DEFAULT_QUERY_BUILDER } from "@src/config/query-builder";
import { useAppContext } from "./app-context";
import { useGetExplorations } from "@src/hooks/use-get-explorations";
import { getModeOfLocation } from "@src/util/fb-exploration/get-mode-of-location";
import { LoadingFullScreen } from "@src/components/loading/loading-full-screen";
import { Form, Result, Spin } from "antd";
import { uniqBy } from "lodash";
import { FormInstance } from "antd/lib/form";
import { getDateRangeWithTime } from "@src/util/time-form";

export const ExploreContext = createContext({} as QueryBuilderRenderProps & ExploreContextProviderProps);

type IProps = {
  children: React.ReactNode;
};
interface QueryBuilderRenderInternalProps {
  restProps: QueryBuilderRenderProps;
}
interface ManualFetchDryRunParams {
  query: Query;
  onSuccess?: (res?: DryRunResponse) => void;
  onError?: (err?: Error) => void;
  onSetters?: () => void;
}

interface ExploreContextProviderProps {
  setProduct: React.Dispatch<React.SetStateAction<IProduct | undefined>>;
  product?: IProduct;
  cubeToken?: string;
  manualFetchDryRun: (params: ManualFetchDryRunParams) => Promise<void>;
  form?: FormInstance<any>;
  cubeApi?: CubeApi;
}
const ExploreProvider: React.FC<PropsWithChildren<ExploreContextProviderProps & QueryBuilderRenderInternalProps>> = ({
  restProps,
  setProduct,
  product,
  children,
  cubeToken,
  manualFetchDryRun,
  cubeApi,
  form,
}) => {
  const value = React.useMemo(() => {
    return { ...restProps, setProduct, product, cubeToken, manualFetchDryRun, form, cubeApi };
  }, [restProps, setProduct, product, cubeToken, manualFetchDryRun, form, cubeApi]);
  return <ExploreContext.Provider value={value}>{children}</ExploreContext.Provider>;
};

export const ExploreContextProvider: React.FC<IProps> = ({ children }) => {
  const { id } = useParsed();
  const { isEditMode, isViewMode } = getModeOfLocation();
  const [form] = Form.useForm();

  const isCreateMode = !isEditMode && !isViewMode;

  const [product, setProduct] = React.useState<IProduct>();
  const [dryRunError, setDryRunError] = React.useState<Error | null>(null);
  const productCode = isCreateMode ? product?.productCode ?? config.DEFAULT_PRODUCT_CODE : product?.productCode;
  const { products } = useAppContext();
  const { exploration, isLoading: isLoadingGetInitValue, error } = useGetExplorations();

  React.useEffect(() => {
    if (isLoadingGetInitValue) return;
    if (!exploration) return;
    if (products.length === 0) return;

    const product = products.find((product) => product.productCode === exploration?.vizState.product_code);
    if (product) {
      setProduct(product);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(exploration), isLoadingGetInitValue, JSON.stringify(products)]);

  const {
    data,
    isLoading: isLoadingGenerateToken,
    isFetching: isFetchingGenerateToken,
  } = useCustom({
    url: `${config.NESTJS_URL}/api/auth/generate-cube-token/${productCode}`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !isCreateMode ? Boolean(productCode) : true,
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
    dataProviderName: "nestjsx",
  });
  const CUBE_TOKEN = data?.data?.token;
  const isHasConfigExplore = isEditMode || isViewMode;

  const isLoadingInitConfig = isHasConfigExplore && isLoadingGetInitValue;
  const isLoading = isLoadingGenerateToken || isFetchingGenerateToken || isLoadingInitConfig;

  const isShowSpinner = !productCode || (!!productCode && isLoading);

  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const manualFetchDryRun = async ({ query, onSuccess, onError, onSetters }: ManualFetchDryRunParams) => {
    await cubejsApi
      .dryRun(query)
      .then((res) => {
        setDryRunError(null);
        onSuccess?.(res);
      })
      .catch((error) => {
        setDryRunError(error);
        onError?.(error);
      })
      .finally(() => {
        onSetters?.();
      });
  };

  const defaultQuery: any = isHasConfigExplore ? exploration?.vizState.query : DEFAULT_QUERY_BUILDER;
  const queryBuilderKey = isHasConfigExplore ? CUBE_TOKEN + id : CUBE_TOKEN;

  const onStateChangeHeuristics = (_: QueryBuilderState, newState: QueryBuilderState) => {
    const customTimeRange = form.getFieldValue("custom-time-range");
    return {
      ...newState,
      query: {
        ...newState.query,
        timeDimensions: uniqBy(
          [
            {
              dimension: config.APP_REPORT_DATE,
              dateRange: getDateRangeWithTime("custom", customTimeRange) ?? "Last 7 days",
            },
            ...(newState.query?.timeDimensions ?? [])
              .filter((item) => !!item.granularity)
              .map((item) => {
                delete item.dateRange;
                return item;
              }),
          ],
          (item) => item.dimension,
        ),
      },
    } as QueryBuilderState;
  };

  if (error?.statusCode === 403) {
    return <Result status="403" title="403" subTitle="Sorry, you are not authorized to access this page." />;
  }
  if (error?.statusCode === 404) {
    return <Result status="404" title="404" subTitle="Exploration not found" />;
  }
  return (
    <Spin spinning={isShowSpinner}>
      {CUBE_TOKEN ? (
        <QueryBuilder
          key={queryBuilderKey}
          cubeApi={cubejsApi}
          defaultChartType="table"
          disableHeuristics={false}
          stateChangeHeuristics={onStateChangeHeuristics}
          initialVizState={exploration?.vizState}
          defaultQuery={defaultQuery}
          wrapWithQueryRenderer={false}
          render={(restProps) => {
            return (
              <ExploreProvider
                form={form}
                setProduct={setProduct}
                cubeApi={cubejsApi}
                restProps={{
                  ...restProps,
                  error: restProps.error ?? dryRunError,
                }}
                product={product}
                cubeToken={CUBE_TOKEN}
                manualFetchDryRun={manualFetchDryRun}
              >
                {children}
              </ExploreProvider>
            );
          }}
        />
      ) : (
        <LoadingFullScreen />
      )}
    </Spin>
  );
};

export const useExploreContext = () => useContext(ExploreContext);
