import { HttpError, useCustomMutation } from "@refinedev/core";
import { useState, useEffect } from "react";
import { config } from "@src/config";
import { IJobMeta, ILoadResponse, IModelAlias, IQuery, IQueryOptions } from "@src/types/ab-testing-exploration";

type LoadAbExplorationProps = {
  productCode?: string;
  modelAlias: IModelAlias;
  query: IQuery;
  options?: IQueryOptions;
  refineOptions?: {
    dataProviderName?: string;
  };
  isSkip?: boolean;
};

export const useLoadAbExploration = ({
  productCode,
  modelAlias,
  query,
  options = {
    // TODO: get form value instead
    cohort_date: "first_signal_date",
    max_day_diff: 99,
  },
  refineOptions,
  isSkip = false,
}: LoadAbExplorationProps) => {
  const [result, setResult] = useState<Record<string, any>[]>();
  const [renderedQuery, setRenderedQuery] = useState<string>();
  const [resultMeta, setResultMeta] = useState<IJobMeta>();
  const [elapsedMillis, setElapsedMillis] = useState<number>();
  const [executionError, setExecutionError] = useState<string>();

  const { mutateAsync, isLoading, error } = useCustomMutation<ILoadResponse, HttpError>({
    mutationOptions: {
      onSettled(data, error) {
        if (error) {
          return;
        }
        setResult(data?.data?.data);
        setResultMeta(data?.data?.meta);
        setElapsedMillis(data?.data?.elapsed_millis);
        setExecutionError(data?.data?.ex);
        setRenderedQuery(data?.data?.rendered_sql);
      },
    },
  });

  async function fetch() {
    mutateAsync({
      ...refineOptions,
      url: `${config.NESTJS_URL}/api/proxy/exploration_v2/ab-test-exploration/${productCode}/${modelAlias}/load`,
      method: "post",
      values: {
        query,
        options: options,
      },
      dataProviderName: "nestjsx",
    });
  }

  useEffect(() => {
    if (productCode && !isSkip) {
      // TODO: enhance document
      fetch();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productCode, modelAlias, isSkip, JSON.stringify(query), JSON.stringify(options)]);

  return {
    isLoading,
    result,
    meta: resultMeta,
    elapsed: elapsedMillis,
    executionError,
    renderedQuery,
    httpError: error,
    refetch: fetch,
  };
};
