import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { useTreeSelectDimension } from "@src/hooks/ab-testing/metric-performance/use-tree-select-dimenson";
import { Badge, Button, Divider, Input, Popover, TreeSelect } from "antd";
import { ChangeEventExtra } from "rc-tree-select/lib/TreeSelect";
import React from "react";
import { FieldSelectorProps } from "react-querybuilder";

export const CustomFieldSelector = (props: FieldSelectorProps) => {
  const { handleOnChange, value, rule } = props;

  const {
    treeSelectProps,
    onChange,
    addItemProps: { valueWithPrefix, isHasTemplate, handleAddItem, badgeProps, inputProps, buttonProps },
  } = useTreeSelectDimension({ disabledWhenEnoughValue: false, optionsProps: { initValues: value ? [value] : [] } });

  const _handleAddItem = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    handleAddItem(e);
    setTimeout(() => {
      handleOnChange(valueWithPrefix);
    }, 0);
  };

  return (
    <TreeSelect
      disabled={!!rule.disabled}
      {...treeSelectProps}
      value={rule.field as any}
      onChange={(value: string[], _: React.ReactNode[], extra: ChangeEventExtra) => {
        onChange(value, _, extra, () => {
          handleOnChange(value);
        });
      }}
      placeholder="Please select"
      dropdownRender={(originNode: any) => {
        return (
          <div>
            {originNode}
            {isHasTemplate && (
              <>
                <Divider className="my-1" />
                <div className="flex justify-between px-2 pb-1">
                  <Input
                    {...inputProps}
                    css={css`
                      .ant-input-prefix {
                        margin-right: 0px !important;
                      }
                    `}
                    prefix={<Badge {...badgeProps} />}
                    suffix={
                      <Popover
                        content={
                          <div className="max-w-[400px]">
                            <p className="m-0">
                              Add event param (ep) to break measure with desired params dimension. Input must match the
                              template:
                            </p>
                            <span className="text-gray-500 italic">Ex: ep_difficulty_tag</span>
                          </div>
                        }
                        title="Add event param"
                        trigger="hover"
                      >
                        <ExclamationCircleOutlined />
                      </Popover>
                    }
                    className="mr-4"
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        _handleAddItem(e);
                        return;
                      }
                      e.stopPropagation();
                    }}
                  />
                  <Button
                    type="primary"
                    {...buttonProps}
                    onClick={() => {
                      _handleAddItem();
                    }}
                  >
                    <PlusOutlined />
                    Add item
                  </Button>
                </div>
              </>
            )}
          </div>
        );
      }}
    />
  );
};
