import React from "react";
import { useLogout } from "@refinedev/core";
import { Layout as AntdLayout, Avatar, Space, Dropdown, MenuProps, Modal, Tag, Empty } from "antd";
import { ImageField, useModal, type RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import { convertKeyToRoleName } from "@src/util/convert-key-to-role-name";
import AmanotesIcon from "@src/assets/images/amanotes_icon.png";
import { useAppContext } from "@src/contexts/app-context";
import { EnumRole } from "@src/types/role";
import { useMe } from "@src/hooks/use-me";

const getColorRole = (roleName: string) => {
  switch (roleName) {
    case "product_owner":
      return "success";
    case "product_explorer":
      return "processing";
    default:
      return "warning";
  }
};

export const ThemedHeaderV2: React.FC<RefineThemedLayoutV2HeaderProps> = ({ sticky }) => {
  const { modalProps, show } = useModal();
  const { mutate: logout } = useLogout();
  const { products } = useAppContext();
  const { user, isSuperAdmin, roles } = useMe();
  console.log({ user });
  const shouldRenderHeader = user && (user.name || user.picture);

  if (!shouldRenderHeader) {
    return null;
  }

  const headerStyles: React.CSSProperties = {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  if (sticky) {
    headerStyles.position = "sticky";
    headerStyles.top = 0;
    headerStyles.zIndex = 99;
  }

  const items: MenuProps["items"] = [
    {
      key: "0",
      label: <div onClick={show}>Your roles</div>,
    },
    {
      key: "1",
      label: <div onClick={() => logout()}>Logout</div>,
    },
  ];
  return (
    <AntdLayout.Header style={headerStyles}>
      <Modal
        {...modalProps}
        title={
          <span className="flex items-center space-x-2">
            <span>Your Roles</span> {isSuperAdmin && <Tag color="success">Super Admin</Tag>}
          </span>
        }
        okButtonProps={{
          hidden: true,
        }}
      >
        {roles.length > 0 || isSuperAdmin ? (
          <div className="p-4 max-h-[400px] overflow-y-auto">
            <div className="flex justify-between sticky top-[-16px] bg-white z-[2]">
              <span className="font-bold">Product</span>
              <span className="font-bold">Role</span>
            </div>
            <div className="">
              {roles.map((item: any) => {
                const product = products?.find((p) => p.code === item.productCode);
                return (
                  <div className="mt-2 flex justify-between items-center" key={item.productCode}>
                    <div className="flex items-center space-x-2">
                      <ImageField value={product?.icon || AmanotesIcon} width={30} />
                      <span>{product?.name || "Unknown"}</span>
                    </div>
                    <Tag color={getColorRole(item.role)} className="mr-0">
                      {convertKeyToRoleName(item.role || EnumRole.ProductViewer)}
                    </Tag>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <Empty description="You are not in product! Please contact your admin" />
        )}
      </Modal>
      <Space>
        <Space size="middle">
          <Dropdown className="ml-auto max-w-max" menu={{ items }} placement="bottomRight">
            <div className="flex px-0 py-2 text-sm text-black transition-all ease-nav-brand flex-row items-center cursor-pointer  ml-auto">
              <div className="relative mr-[10px]">
                {user?.picture && <Avatar src={user?.picture} alt={user?.display_name} />}
              </div>
              <span className="sm:inline pl-2 text-ellipsis">{user.email || "Anonymous"}</span>
            </div>
          </Dropdown>
        </Space>
      </Space>
    </AntdLayout.Header>
  );
};
