import { CopyOutlined } from "@ant-design/icons";
import { BaseKey } from "@refinedev/core";
import { useDuplicateFirebaseExplore } from "@src/hooks/fb-explore/use-duplicate-firebase-explore";
import { Button, Popconfirm, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

type DuplicateButtonProps = {
  id?: string | BaseKey;
  form: FormInstance<any>;
  disabled?: boolean;
  message?: string;
};
export const DuplicateButton: React.FC<DuplicateButtonProps> = ({ id, form, message = "Duplicate", disabled }) => {
  const { handleDuplicate } = useDuplicateFirebaseExplore({
    id: id as any,
    replace: false,
    form,
  });

  const onDuplicate = async () => {
    handleDuplicate({});
  };
  return (
    <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={() => onDuplicate()}>
      <Tooltip title={message}>
        <Button icon={<CopyOutlined />} size="small" disabled={disabled} />
      </Tooltip>
    </Popconfirm>
  );
};
