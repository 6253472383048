import { Form, Input } from "antd";
import { useCan, useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { ABFilterFormContent } from "./form";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "../../enum";
import React from "react";
import { ChartFormInstanceType, FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { getGroupFieldValue, getParamsFieldValue } from "@src/util/ab-testing/params";
import { FilterExplorationLayout } from "../layout/filter-exploration-layout";
import { ALL_EXTRA_FIELDS_FILTER } from "../../contanst/filter-fields";
import { convertDateRangeToDayJS, convertDateRangeToString } from "@src/util/time-form";
import { COHORT_DATE } from "../../contanst";
import { css } from "@emotion/react";

type FilterExplorationProps = {
  form: FormInstance<FilterFormInstanceType>;
  chartFormInstance: FormInstance<ChartFormInstanceType>;
};
export const FilterExploration: React.FC<FilterExplorationProps> = ({ form, chartFormInstance }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const { data, isLoading: isLoadingGenerateToken } = useCustom({
    url: `${config.NESTJS_URL}/api/auth/generate-cube-token/${productCodeWatch}`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !!productCodeWatch,
      onSuccess: (data) => {
        const token = data?.data?.token;
        if (token) {
          form.setFieldValue(FilterFormFieldsEnum.PRODUCT_TOKEN, token);
        }
      },
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
    dataProviderName: "nestjsx",
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const handleFinish = (values: any) => {
    const _values: Record<string, any> = {};

    [
      FilterFormFieldsEnum.PRODUCT_CODE,
      FilterFormFieldsEnum.PRODUCT_TOKEN,
      FilterFormFieldsEnum.ABA_ID,
      FilterFormFieldsEnum.AMA_APP_IDS,
      FilterFormFieldsEnum.PLATFORM,
      FilterFormFieldsEnum.VARIANTS,
      FilterFormFieldsEnum.VARIANTS_OPTIONS,
      FilterFormFieldsEnum.COHORT_DATE,
      FilterFormFieldsEnum.MAX_DAY_DIFF,
      FilterFormFieldsEnum.EXTRA_INSTALL_DATE,
      FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE,
      FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED,
    ].forEach((field) => {
      if ([FilterFormFieldsEnum.EXTRA_INSTALL_DATE, FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE].includes(field)) {
        const _parseValue = JSON.parse(values[FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED] ?? "{}");
        _values[field] = _parseValue?.[field] ?? convertDateRangeToString(_parseValue?.[field]);
      } else {
        _values[field] = values[field];
      }
    });
    chartFormInstance.setFieldValue(ChartFormFieldsEnum.FILTER_FIELDS, JSON.stringify(_values));
  };

  const getInitValue = () => {
    const filterForm = getGroupFieldValue("filterForm") ?? {};
    const hasConfigExited = Object.keys(filterForm).length > 0;

    const filterValuesConfirmed: Record<string, any> = {};
    ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
      const value = filterForm[field];

      if (value) {
        filterValuesConfirmed[field] = value;
      }
    });

    const initFilterOutUserField = {
      [FilterFormFieldsEnum.INVALID_COUNTRIES]: true,
      [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: true,
      [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: true,
      [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: true,
      // [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: false, // true as default later
    };

    return {
      [FilterFormFieldsEnum.COHORT_DATE]: COHORT_DATE.INSTALL_DATE,
      [FilterFormFieldsEnum.MAX_DAY_DIFF]: config.DEFAULT_MAX_DAY_DIFF,
      ...filterForm,
      ...(!hasConfigExited
        ? {
            ...initFilterOutUserField,
            [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: JSON.stringify(initFilterOutUserField),
          }
        : {
            [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: JSON.stringify(filterValuesConfirmed),
          }),
      [FilterFormFieldsEnum.IS_SHOW_CHIP]: true,
      [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: convertDateRangeToDayJS(
        filterForm?.[FilterFormFieldsEnum.EXTRA_INSTALL_DATE],
      ),
      [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: convertDateRangeToDayJS(
        filterForm?.[FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE],
      ),
      [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
    };
  };

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <Form
        name={FORM_NAME.FILTER_FORM}
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={getInitValue()}
      >
        <FilterExplorationLayout form={form} isLoadingGenerateToken={isLoadingGenerateToken}>
          <ABFilterFormContent form={form} />
        </FilterExplorationLayout>
        <Form.Item name={FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED} hidden>
          <Input />
        </Form.Item>
      </Form>
    </CubeProvider>
  );
};
