export function extractRatioParts(template: string, regex?: RegExp): { numerator: string; denominator: string } | null {
  if (!regex) return null;
  const _regex = new RegExp(regex);

  const match = _regex.exec(template);

  if (match?.groups) {
    return {
      numerator: match.groups.event_name1,
      denominator: match.groups.event_name2,
    };
  }
  return null;
}
