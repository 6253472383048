import { Form, Tabs } from "antd";
import React from "react";
import { MetricPerformanceItem } from "./metric-performance-item";
import { TargetKey, useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { MetricParentTabName } from "./metric-performance-item/tab-name";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import { MAX_METRIC_TABS } from "@src/constant/ab-testing";

export const MetricPerformanceTabs: React.FC = () => {
  const { activeKey, setActiveKey, add, form } = useMetricPerformanceContext();

  const metricTabItemsWatch = Form.useWatch(ChartFormFieldsEnum.METRIC_TAB_ITEMS, form) ?? [];

  const metricTimeRangeWatch = Form.useWatch(ChartFormFieldsEnum.METRIC_TIME_RANGE, form) ?? [];
  const baseVariant = Form.useWatch(ChartFormFieldsEnum.METRIC_BASE_VARIANTS, form) ?? "";
  const allowShowMetricTabs = metricTimeRangeWatch.length > 0 && baseVariant.length > 0;

  const onChange = (key: string) => {
    setActiveKey(key);
  };

  const onEdit = (targetKey: TargetKey, action: "add" | "remove") => {
    if (action === "add") {
      add();
    }
  };

  React.useEffect(() => {
    setActiveKey(form.getFieldValue(ChartFormFieldsEnum.METRIC_TAB_ITEMS)?.[0]?.key);
  }, [form, setActiveKey]);

  return (
    <Tabs
      style={{
        display: allowShowMetricTabs ? "block" : "none",
      }}
      id="metric-performance-tabs"
      onChange={onChange}
      activeKey={activeKey}
      type="editable-card"
      onEdit={onEdit}
      // destroyInactiveTabPane
      hideAdd={metricTabItemsWatch?.length >= MAX_METRIC_TABS}
      items={metricTabItemsWatch.map((item: any) => {
        return {
          label: <MetricParentTabName key={item.key} targetKey={item.key} />,
          key: item.key,
          children: (
            <ChartWrapperContextProvider>
              <MetricPerformanceItem key={item.key} targetKey={item.key} />
            </ChartWrapperContextProvider>
          ),
        };
      })}
    />
  );
};
