import React from "react";
import { useGetGeneralField } from "./use-get-general-field";
import { ColumnsType } from "antd/es/table";
import { useChartWrapperContext } from "@src/contexts/ab-testing/chart-wrapper-context";
import { Tag, Tooltip } from "antd";

type UseGetRawDataUserCountProps = {
  loadResult: Record<string, any>[];
  keyLabel: string;
  groupBy: string;
  countValueBy?: string;
  chartKey?: string;
  modelAlias?: string;
};

export const useRawTableUserCount = ({
  chartKey,
  loadResult,
  keyLabel,
  groupBy,
  countValueBy = "user_count",
  modelAlias,
}: UseGetRawDataUserCountProps) => {
  const { variants, getExpAliasById } = useGetGeneralField({});
  const { updateChartState } = useChartWrapperContext();
  const [dataSource, setDataSources] = React.useState<any[]>([]);
  const [dataExports, setDataExports] = React.useState<any[]>([]);

  const generateKeyBy = (variant: string, key: string) => {
    return `${key}-${getExpAliasById(variant)}`;
  };

  // const generateLabelBy = (variant: string, key: string) => {
  //   return `${key} of ${getExpAliasById(variant)}`;
  // };

  React.useEffect(() => {
    if (!variants) return;
    const _dataSource: any[] = [];
    const groupedResult = Object.groupBy(loadResult ?? [], (item) => {
      return item?.[groupBy];
    });

    const _totalUserCount = variants?.reduce((result, item) => {
      return {
        ...result,
        [item]: 0,
      };
    }, {} as Record<string, number>);

    Object.keys(groupedResult).forEach((key) => {
      let _result = {
        [groupBy]: key,
      };

      variants.forEach((v) => {
        const userCount = groupedResult[key]?.find((t) => t.exp_group === v)?.[countValueBy] ?? 0;
        _totalUserCount[v] += userCount;
        _result = {
          ..._result,
          [generateKeyBy(v, countValueBy)]: userCount,
        };
      });
      _dataSource.push(_result);
    });
    // const dataExports: any[] = [];

    const mapDataSourcesWithPercentage = _dataSource.map((item) => {
      const percentOfVariants = variants.map((v) => {
        return {
          [generateKeyBy(v, "user_percent")]: (item[generateKeyBy(v, countValueBy)] * 100) / _totalUserCount[v],
        };
      });
      // const percentOfVariantsWithLabel = variants.map((v) => {
      //   return {
      //     [generateLabelBy(v, countValueBy)]: item[generateKeyBy(v, countValueBy)],
      //     [generateLabelBy(v, "user_percent")]:
      //       ((item[generateKeyBy(v, countValueBy)] * 100) / _totalUserCount[v]).toFixed(2) + "%",
      //   };
      // });
      // dataExports.push({
      //   [groupBy]: item[groupBy],
      //   ...Object.assign({}, ...percentOfVariantsWithLabel),
      // });
      return {
        ...item,
        ...Object.assign({}, ...percentOfVariants),
      };
    });
    setDataExports(loadResult);
    setDataSources(mapDataSourcesWithPercentage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variants), JSON.stringify(loadResult)]);

  React.useEffect(() => {
    if (!dataExports) return;
    if (!chartKey || !modelAlias) return;
    updateChartState({
      chartKey,
      modelAlias,
      dataSource: dataExports,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(dataExports)]);

  const columns: ColumnsType<any> = React.useMemo(() => {
    return [
      {
        title: keyLabel,
        dataIndex: groupBy,
        key: groupBy,
        width: 150,
        sorter: (a: any, b: any) => a[groupBy].localeCompare(b[groupBy]),
      },
      {
        title: "Variants",
        children: [
          ...(variants?.map((v) => {
            const lengthOfVariant = getExpAliasById(v).length;
            const widthBaseVariant = lengthOfVariant * 5 >= 150 ? lengthOfVariant * 5 : 150;
            return {
              title: getExpAliasById(v),
              dataIndex: v,
              key: v,
              children: [
                {
                  title: "User Count",
                  dataIndex: generateKeyBy(v, countValueBy),
                  key: generateKeyBy(v, countValueBy),
                  width: widthBaseVariant,
                  sorter: (a: any, b: any) => a[generateKeyBy(v, countValueBy)] - b[generateKeyBy(v, countValueBy)],
                },
                {
                  title: "User Percentage",
                  dataIndex: generateKeyBy(v, "user_percent"),
                  key: generateKeyBy(v, "user_percent"),
                  width: widthBaseVariant,
                  render: (value: number) => {
                    return value || value === 0 ? `${value?.toFixed(2)}%` : <Tag color="blue">NULL</Tag>;
                  },
                },
              ],
            };
          }) ?? []),
        ],
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(variants)]);

  return {
    tableProps: {
      rowKey: groupBy,
      dataSource,
      columns,
    },
  };
};
