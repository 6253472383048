import { shared } from "./shared";

export const getUserInfo = () => {
  const userInfo = shared.get("user");

  if (!userInfo) return null;

  return {
    userId: userInfo.email,
    email: userInfo.email,
  };
};
