import { Button, ConfigProvider, Descriptions, Space, Tag, Tooltip } from "antd";
import React, { ReactNode } from "react";
import { ABExplorationKeyWords } from "@src/constant/ab-testing/ad-testing-exploration.enum";
import { Typography } from 'antd';
import { 
  RiseOutlined, 
  FallOutlined, 
  WarningOutlined} from "@ant-design/icons";
import { mean } from "lodash";
import { MIN_VARIANT_SAMPLE_SIZE } from "@src/constant/ab-testing";
import { css } from "@emotion/react";

const { Text } = Typography;

const SampleSizeWithWarning: React.FC<{ 
  sampleSize?: number;
  warningIcon?: ReactNode;
  hiddenWarningText?: boolean;
  hiddenWarningIcon?: boolean;
  highlightOnWarning?: boolean;
  shouldWarning?: (sampleSize?: number) => boolean
}> = ({ 
  sampleSize, 
  warningIcon = <WarningOutlined/>,
  hiddenWarningText = false,
  hiddenWarningIcon = false,
  highlightOnWarning = true,
  shouldWarning,
}) => {
  const _shouldWarning = shouldWarning === undefined ? false : (shouldWarning(sampleSize) || false)
  const _warningIcon = hiddenWarningIcon ? null : warningIcon
  // const _warningText = hiddenWarningText ? null : "Not enough sample size (at least 300)"
  const _warningText = hiddenWarningText ? null : `Insufficient for reliable results (ideal >= ${MIN_VARIANT_SAMPLE_SIZE} )`
  return <Space>
    { sampleSize === undefined ? "-" : sampleSize}
    <Space className={sampleSize !== undefined && _shouldWarning && highlightOnWarning ? "text-yellow-500" : ""}>
      { sampleSize === undefined ? null : (
        _shouldWarning ? _warningIcon : null
      )}
      { sampleSize === undefined ? null : (
        _shouldWarning ? _warningText : null
      )}
    </Space>
    
  </Space>
}

const ChangePercent: React.FC<{ 
  changePercent?: number;
  textColorClassName: string
}> = ({ 
  changePercent, 
  textColorClassName
}) => {
  // return changePercent !== undefined ? `${(changePercent * 100 as number).toFixed(3)} %` : "-"
  return changePercent === undefined || changePercent === null ? "-" : <Text className={`${textColorClassName}`}>
    {
      changePercent > 0 ? <RiseOutlined /> : (changePercent < 0 ? <FallOutlined/> : "")
    }
    <> </>
    {Math.abs(changePercent * 100).toFixed(2)} %
  </Text>
}


const BaseReport: React.FC<{ 
  mean: number;
  pValue?: number;
  upliftPercent?: number;
  sampleSize?: number;
  colorFn?: (pValue?: number, upliftPercent?: number) => {
    bg: string,
    upliftPercentTextColor: string,
  }
}> = ({ 
  mean,
  pValue, 
  upliftPercent, 
  sampleSize, 
  colorFn,
}) => {

  const {
    bg: bgColor,
    upliftPercentTextColor,
  } = colorFn !== undefined ? colorFn(pValue, upliftPercent) : {
    bg: "bg-neutral-100",
    upliftPercentTextColor: "text-slate-900",
  }

  const uplift = (
    <ChangePercent changePercent={upliftPercent} textColorClassName={upliftPercentTextColor}/>
  )
  
  const subValue = (
    <div className="text-[11px] w-full text-slate-400 group-hover:font-bold group-hover:text-slate-600 duration-300">
      <div className="grid grid-flow-row-dense grid-cols-9 group-hover:text-[13px]">
        <Space>
          {mean !== undefined && mean !== null ? (
            mean !== 0 ? (mean as number).toFixed(5) : 0
          ) : "-"}
          /
          <SampleSizeWithWarning sampleSize={sampleSize as number} highlightOnWarning={false} shouldWarning={(sampleSize) => sampleSize === undefined || sampleSize < MIN_VARIANT_SAMPLE_SIZE} hiddenWarningText/>
        </Space>
      </div>
    </div>
  )


  return <div className={`${bgColor} min-h-[50px] font-semibold flex flex-1 p-1 group`}>
    <div className="flex-1">
      {uplift}
      {subValue}
    </div>
  </div>

};



export const VariantReport: React.FC<{ 
  groupName: string,
  groupAlias: string,
  report: any;
  isSignificantFn?: (pValue: number) => boolean;
  colorFn: (pValue?: number, upliftPercent?: number) => {
    bg: string,
    upliftPercentTextColor: string,
  }
}> = ({ 
  // mean,
  groupName,
  groupAlias,
  report,
  isSignificantFn,
  colorFn,
}) => {
  const {
    method, 
    p_value, 
    mean: mean_variant, 
    uplift_percent, 
    lower_bound_uplift_percent, 
    upper_bound_uplift_percent, 
    error_message,
    [ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize
  } = report || {}
  
  const isSignificant = isSignificantFn ? isSignificantFn(p_value) : false
  const tooltipContent = (
      <Descriptions 
        title={groupAlias} 
        // layout="vertical"
        size="small"
        // bordered
        column={{ xs: 2, sm: 2, md: 2, lg: 5, xl: 5, xxl: 5 }}
        extra={isSignificant ? <Tag className="text-[20px] font-light">Significant</Tag> : null}
      >
        <Descriptions.Item label="Method" span={5}>{method ? method : "-"}</Descriptions.Item>
        <Descriptions.Item label="Sample size" span={5}>
          <SampleSizeWithWarning sampleSize={sampleSize as number} shouldWarning={(sampleSize) => sampleSize === undefined || sampleSize < MIN_VARIANT_SAMPLE_SIZE}/>
        </Descriptions.Item>
        <Descriptions.Item label="Mean" span={3}>{mean_variant !== undefined ? mean_variant : "-"}</Descriptions.Item>
        <Descriptions.Item label="% Change" span={2}>
          <ChangePercent changePercent={uplift_percent} textColorClassName={colorFn(p_value, uplift_percent).upliftPercentTextColor}/>  
        </Descriptions.Item>
        <Descriptions.Item label="Credible Interval" span={5}>
          <Space>
          {"["}
          {lower_bound_uplift_percent ? `${(lower_bound_uplift_percent * 100 as number).toFixed(3)} %` : "-"}
          {","}
          {upper_bound_uplift_percent ? `${(upper_bound_uplift_percent * 100 as number).toFixed(3)} %` : "-"}
          {"]"}
          </Space>
        </Descriptions.Item>
        
        <Descriptions.Item label="P-Value" span={2}>
          {p_value ? (
            (p_value as number) > 0.001 ? (p_value as number).toFixed(3) : "< 0.001"
          ) : "-"}
        </Descriptions.Item>
        {
          error_message !== undefined && error_message !== null ? (
            <Descriptions.Item label="Error" span={3}>
              <div className="text-red-400">{error_message}</div>
            </Descriptions.Item>
          ): null
        }
        
      </Descriptions>
  )
  return (
    <Tooltip 
      // title={method !== undefined ? tooltipContent : null} 
      title={tooltipContent} 
      style={{maxWidth: "900px"}}
      overlayInnerStyle={{width: "500px", padding: "20px",  backgroundColor: "#fff"}}
      overlayStyle={{width: "500px", maxWidth: "500px"}}
      placement="top"
      arrow={false}
    >
      <div>
      <BaseReport 
        mean={mean_variant} 
        pValue={p_value} 
        upliftPercent={uplift_percent} 
        sampleSize={sampleSize} 
        colorFn={colorFn}
      />
      </div>
    </Tooltip>
  )
  
};


export const ControlReport: React.FC<{ 
  groupName: string;
  groupAlias: string;
  report: any;
}> = ({
  groupName, 
  groupAlias, 
  report 
}) => {
  const {mean,  [ABExplorationKeyWords.SAMPLE_SIZE]: sampleSize} = report || {}
  return <BaseReport mean={mean} sampleSize={sampleSize}/>
  
};
