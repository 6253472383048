import { useList } from "@refinedev/core";

export const useGetDistinctFirebaseProduct = () => {
  const { data, isLoading, isFetching } = useList({
    dataProviderName: "nestjsx",
    resource: "explorations/products",
  });
  return {
    data,
    isLoading: isLoading || isFetching,
  };
};
