export const convertArrayToCSV = (arr: any[]) => {
  const array = [Object.keys(arr[0])].concat(arr);

  return array
    .map((it) => {
      return Object.values(it).reduce((acc, curr) => {
        acc += curr + "\t";
        return acc;
      }, "");
    })
    .join("\n");
};
