import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { ProTable } from "@ant-design/pro-components";
import { ImageField, List, useDrawerForm, useTable } from "@refinedev/antd";
import { Button, Popconfirm, Space, Tooltip } from "antd";
import { ProductCreate } from "./create";
import { useLocalStorage } from "@src/hooks/use-localstorage";
import { ProductEdit } from "./edit";
import { CanAccess, useGetIdentity } from "@refinedev/core";
import React from "react";
import dayjs from "dayjs";
import { useMe } from "@src/hooks/use-me";

export const ProductListing: React.FC = () => {
  const { isSuperAdmin } = useMe();
  const [productCode, setProductCode] = React.useState("");
  const { drawerProps, show, saveButtonProps, formProps, close } = useDrawerForm({
    resource: "products",
    action: "create",
    warnWhenUnsavedChanges: false,
    dataProviderName: "nestjsx",
    meta: {
      headers: {
        "Product-Code": productCode,
      },
    },
  });

  const {
    drawerProps: drawerPropsEdit,
    show: showEdit,
    saveButtonProps: saveButtonPropsEdit,
    formProps: formPropsEdit,
    close: closeEdit,
    formLoading: formLoadingEdit,
  } = useDrawerForm({
    resource: "products",
    action: "edit",
    warnWhenUnsavedChanges: false,
    meta: {
      join: [
        {
          field: "analytic_events",
          select: ["id", "event_name"],
        },
        {
          field: "analytic_events.event_params",
          select: ["id", "param_type", "param_key"],
        },
      ],
      headers: {
        "Product-Code": productCode,
      },
    },
    dataProviderName: "nestjsx",
  });
  const { tableProps } = useTable({
    resource: "products",
    meta: {
      join: [
        {
          field: "analytic_events",
        },
        {
          field: "analytic_events.event_params",
        },
      ],
    },
    dataProviderName: "nestjsx",
  });
  const [products, setProducts] = useLocalStorage<any[]>("products", []);
  const onCreate = (product: any) => {
    setProducts([product, ...products]);
  };

  const columns: any = [
    {
      title: "Product",
      dataIndex: "name",
      key: "name",
      render: (value: string, record: any) => {
        return (
          <div className="flex space-x-2 items-center">
            <div className="w-[30px] h-[30px]">
              <ImageField value={record.icon} className="w-full h-full" preview={false} />
            </div>
            <span>{value}</span>
          </div>
        );
      },
    },

    {
      title: "Product code",
      dataIndex: "code",
    },
    {
      title: "Last modified",
      dataIndex: "updated_at",
      render: (value: number) => {
        return <span>{dayjs(value).format("DD/MM/YYYY HH:mm:ss")}</span>;
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_: any, record: any) => {
        return (
          <Space>
            <CanAccess
              resource="product"
              action="edit"
              params={{
                productCode: record.code,
              }}
            >
              <Tooltip title="Edit">
                <Button
                  icon={<EditOutlined />}
                  size="small"
                  onClick={() => {
                    showEdit(record.id);
                    setProductCode(record.code);
                  }}
                />
              </Tooltip>
            </CanAccess>
          </Space>
        );
      },
    },
  ];

  const eventColumns = [
    {
      title: "Event name",
      dataIndex: "event_name",
    },
  ];

  const paramColumns = [
    {
      title: "Parameter name",
      dataIndex: "param_key",
    },
    {
      title: "Parameter type",
      dataIndex: "param_type",
    },
  ];

  return (
    <>
      <ProductCreate
        onCreate={onCreate}
        drawerProps={drawerProps}
        saveButtonProps={saveButtonProps}
        formProps={formProps}
        close={close}
      />
      <ProductEdit
        drawerProps={drawerPropsEdit}
        saveButtonProps={saveButtonPropsEdit}
        formProps={formPropsEdit}
        close={closeEdit}
        isLoading={formLoadingEdit}
      />
      <List
        canCreate
        createButtonProps={{
          onClick: () => show(),
          hidden: !isSuperAdmin,
        }}
      >
        <ProTable
          {...tableProps}
          rowKey={"id"}
          columns={columns}
          search={false}
          options={false}
          expandable={{
            expandedRowRender: (record) => {
              return (
                <ProTable
                  rowKey={"event_name"}
                  search={false}
                  options={false}
                  columns={eventColumns}
                  dataSource={record.analytic_events}
                  pagination={false}
                  expandable={{
                    expandedRowRender: (record: any) => {
                      return (
                        <ProTable
                          rowKey={"param_key"}
                          columns={paramColumns}
                          dataSource={record.event_params}
                          search={false}
                          options={false}
                          pagination={false}
                        />
                      );
                    },
                  }}
                />
              );
            },
          }}
        />
      </List>
    </>
  );
};
