import { css } from "@emotion/react";
import { Spin, theme } from "antd";

export const LoadingFullScreenWithText = ({ text = "" }: { text?: string }) => {
  const { useToken } = theme;
  const { token } = useToken();
  return (
    <div className="bg-white w-full h-screen absolute top-0 left-0 z-[999999999] flex items-center justify-center flex-col">
      <Spin />
      <span
        className="block mt-4 text-[12px]"
        css={css`
          color: ${token.colorPrimary};
        `}
      >
        {text}
      </span>
    </div>
  );
};
