import { useAbTestExplorationContext } from "@src/contexts/ab-testing/ab-test-exploration-context";
import { toTitle } from "@src/helpers/text-helper";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { IDimension } from "@src/types/ab-testing-exploration";
import { Form, InputRef } from "antd";
import { uniq, uniqBy } from "lodash";
import React from "react";
import { type ChangeEventExtra } from "rc-tree-select/lib/TreeSelect";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { PresetStatusColorType } from "antd/lib/_util/colors";
import { SupportedModelAlias } from "@src/constant/ab-testing/ad-testing-exploration.enum";

type ITemplateMember = IDimension & {
  prefix: string;
};

type ITreeDataOptions = {
  value: string;
  title: string | React.ReactNode;
  disabled?: boolean;
  children?: ITreeDataOptions[];
};

const MAX_LENGTH_DIMENSION = 3;
export const useTreeSelectDimension = ({
  multiple = false,
  disabledWhenEnoughValue = true,
  firebaseOptions,
  optionsProps,
}: {
  multiple?: boolean;
  disabledWhenEnoughValue?: boolean;
  optionsProps?: {
    initValues: string[];
    syncValueWithForm?: {
      enabled?: boolean;
      fieldName: string[] | string;
    };
  };
  firebaseOptions?: {
    prefix?: string;
  };
}) => {
  const { prefix = "ep_" } = firebaseOptions ?? {};
  const { initValues } = optionsProps ?? {};

  const maxDimensionSelected = multiple ? MAX_LENGTH_DIMENSION : 1;
  const { targetedModel, firebaseEventParams, isRequiredEventNameInFilter } = useMetricPerformanceContext();

  const inputRef = React.useRef<InputRef>(null);

  const [valueSelected, setValueSelected] = React.useState<string[] | undefined>([]);
  const [options, setOptions] = React.useState<ITreeDataOptions[]>([]);
  const [templateMember, setTemplateMember] = React.useState<ITemplateMember>();
  const [newMember, setNewMember] = React.useState<string>("");

  const { getAvailableDimensionsTree } = useAbTestExplorationContext();

  const availableDimensionsTree = React.useMemo(() => {
    return getAvailableDimensionsTree({ modelAlias: targetedModel }) || [];
  }, [targetedModel, getAvailableDimensionsTree]);

  const aliasList = Object.keys(availableDimensionsTree);
  const createOptionsBaseModalAlias = ({
    availableDimensionsTree,
    eventParamsOptions,
  }: {
    availableDimensionsTree: string[] | Record<string, IDimension[]>;
    eventParamsOptions?: ITreeDataOptions[];
  }) => {
    let _templateMember: ITemplateMember | undefined;

    const result = Object.entries(availableDimensionsTree).map(([key, value]) => {
      const indexTemplateMember = value.findIndex((item: IDimension) => item.matched_regex);
      if (indexTemplateMember !== -1) {
        _templateMember = {
          ...value[indexTemplateMember],
          prefix: value[indexTemplateMember].name?.split("_")?.[0],
        };
      }
      const _children = value.map((item: IDimension) => ({
        title: toTitle(item.name),
        value: item.name,
        disabled: !isRequiredEventNameInFilter() && item.name === "event_name",
      }));
      if (eventParamsOptions && key === SupportedModelAlias.EVENT_PARAM) {
        return {
          title: toTitle(key),
          value: key,
          children: eventParamsOptions,
        };
      }
      return {
        title: toTitle(key),
        value: key,
        children: _children,
      };
    });

    return {
      templateMember: _templateMember,
      options: result,
    };
  };

  const disabledItemOption = React.useCallback(
    (valueSelected: string[] | undefined, options?: any[]) => {
      if (!disabledWhenEnoughValue) return null;
      if (valueSelected?.length === 0) return null;
      const newData = options?.map((item) => {
        return {
          ...item,
          children: item.children?.map((child: any) => {
            return {
              ...child,
              disabled:
                valueSelected && !valueSelected?.includes(child.value) && valueSelected.length >= maxDimensionSelected,
            };
          }),
        };
      });
      return newData;
    },
    [disabledWhenEnoughValue, maxDimensionSelected],
  );

  const fieldRegisterToSyncWatch = Form.useWatch(optionsProps?.syncValueWithForm?.fieldName);

  React.useEffect(() => {
    if (!optionsProps?.syncValueWithForm?.enabled) return;
    setValueSelected(fieldRegisterToSyncWatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(fieldRegisterToSyncWatch), optionsProps?.syncValueWithForm?.enabled]);

  React.useEffect(() => {
    if (!initValues) return;
    setValueSelected(initValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let _eventParamsOptions: ITreeDataOptions[] | undefined = undefined;

    if (targetedModel === SupportedModelAlias.FIREBASE) {
      if (!firebaseEventParams || firebaseEventParams.length === 0) return;
      const _firebaseEventParams = uniq([
        ...(initValues?.filter((item) => item.startsWith(prefix)) ?? []),
        ...(firebaseEventParams ?? []).map((item) => `ep_${item}`),
      ]);
      const formatEventParams = _firebaseEventParams.map((item: any) => ({
        title: item,
        value: item,
        disabled: false,
      }));
      _eventParamsOptions = formatEventParams;
    }
    const { templateMember, options } = createOptionsBaseModalAlias({
      availableDimensionsTree,
      eventParamsOptions: _eventParamsOptions,
    });

    setTemplateMember(templateMember);
    const newOption = disabledItemOption(initValues, options) ?? options;

    setOptions(newOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableDimensionsTree,
    targetedModel,
    firebaseEventParams,
    JSON.stringify(initValues),
    prefix,
    disabledItemOption,
  ]);

  const addOptions = (
    parent: string,
    newOption: ITreeDataOptions,
    _options: ITreeDataOptions[],
  ): ITreeDataOptions[] => {
    return _options.map((item) => {
      if (item.value === parent) {
        return {
          ...item,
          children: uniqBy([newOption, ...(item.children ?? [])], "value"),
        };
      }
      return item;
    });
  };

  const isMatchedRegex =
    templateMember?.matched_regex && `${templateMember?.prefix + "_" + newMember}`.match(templateMember?.matched_regex);
  const memberWithPrefix = `${templateMember?.prefix}_${newMember}`;

  const handleAddItem = (e?: any) => {
    e?.stopPropagation();
    if (!isMatchedRegex) return;
    if (!newMember) return;

    const newValueSelected = uniq([memberWithPrefix, ...(valueSelected ?? [])]).slice(0, maxDimensionSelected);
    const newOptions = addOptions(
      SupportedModelAlias.EVENT_PARAM,
      { value: memberWithPrefix, title: memberWithPrefix },
      options,
    );
    setOptions(disabledItemOption(newValueSelected, newOptions) ?? newOptions);
    setValueSelected(newValueSelected);
    setNewMember("");
  };

  const handleTreeSelectChange = (value: string[], _: React.ReactNode[], extra: ChangeEventExtra, cb?: () => void) => {
    if (aliasList.includes(extra.triggerValue as string)) return;
    setValueSelected(value);
    setOptions(disabledItemOption(value, options) ?? options);
    cb?.();
  };
  return {
    addItemProps: {
      handleAddItem,
      isHasTemplate: Boolean(templateMember),
      valueWithPrefix: memberWithPrefix,
      inputProps: {
        ref: inputRef,
        onChange: (e: React.ChangeEvent<HTMLInputElement>) => setNewMember(e.target.value),
        value: newMember,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === "Enter") {
            handleAddItem(e);
            return;
          }
          e.stopPropagation();
        },
      },
      badgeProps: {
        status: (isMatchedRegex ? "success" : "error") as PresetStatusColorType,
        text: `${templateMember?.prefix}_`,
      },
      buttonProps: {
        disabled: !isMatchedRegex,
        onClick: handleAddItem,
      },
    },
    onChange: handleTreeSelectChange,
    setValueSelected: setValueSelected,
    valueSelected,
    treeSelectProps: {
      treeData: options,
      value: valueSelected,
      onChange: handleTreeSelectChange,
      treeLine: true,
      //
      showSearch: true,
      filterTreeNode: (input: string, node: any) => {
        return node?.title.toLowerCase().includes(input?.toLowerCase());
      },
      style: { width: "100%" },
      allowClear: true,
      multiple: multiple,
      treeDefaultExpandAll: true,
    },
  };
};
