import { shared } from "@src/util/shared";

export const useMe = () => {
  const permissions = shared.get("permissions") || [];
  const roles = shared.get("roles") || [];
  const user = shared.get("user") || {};
  const superAdmins: string[] = shared.get("superAdmins") || [];
  const isSuperAdmin = superAdmins.includes(user.email);
  return { permissions, roles, user, superAdmins, isSuperAdmin };
};
