import packageJSON from "../../package.json";

export const config = {
  BACKEND_URL: process.env.VITE_BACKEND_URL || "",
  SIDEBAR_WIDTH: 240,
  RECHART_HEIGHT: 400,
  DATE_FORMAT: "YYYY-MM-DD",
  VERSION: packageJSON.version,
  DEFAULT_PRODUCT_CODE: "DR",
  CUBE_URL: process.env.VITE_CUBE_URL || "",
  MAX_CHART_SIZE: 10,
  NESTJS_URL: import.meta.env.VITE_NESTJS_URL,
  DEFAULT_TIME_RANGE: "Last 7 days",
  AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN,
  DEBOUNCE_TIME: {
    SHORT_DELAY: 200,
    MEDIUM_DELAY: 500,
    LONG_DELAY: 800,
    EXTRA_LONG_DELAY: 2 * 1000,
  },
  DEFAULT_PAGE_SIZE: 100,
  TOKEN_KEY: "tokens",
  DEFAULT_MAX_DAY_DIFF: 7,
  // dim
  FIREBASE_EVENT_TIMESTAMP: "firebase.event_timestamp",
  APP_REPORT_DATE: "app_report.date_tzutc",
  APP_REPORT_AMA_ID: "app_report.ama_app_id",
  METRIC_FILTER_DEFAULT: {
    combinator: "and",
    rules: [],
  },
};

export const queryBuilderOperators: Record<
  "string" | "boolean" | "number" | "time",
  { name: string; title: string }[]
> = {
  string: [
    {
      name: "contains",
      title: "contains",
    },
    {
      name: "notContains",
      title: "does not contain",
    },
    {
      name: "equals",
      title: "equals",
    },
    {
      name: "notEquals",
      title: "does not equal",
    },
    {
      name: "set",
      title: "is set",
    },
    {
      name: "notSet",
      title: "is not set",
    },
    {
      name: "startsWith",
      title: "starts with",
    },
    {
      name: "notStartsWith",
      title: "does not start with",
    },
    {
      name: "endsWith",
      title: "ends with",
    },
    {
      name: "notEndsWith",
      title: "does not end with",
    },
  ],
  boolean: [
    {
      name: "equals",
      title: "equals",
    },

    {
      name: "set",
      title: "is set",
    },
    {
      name: "notSet",
      title: "is not set",
    },
  ],
  number: [
    {
      name: "equals",
      title: "equals",
    },
    {
      name: "notEquals",
      title: "does not equal",
    },
    {
      name: "set",
      title: "is set",
    },
    {
      name: "notSet",
      title: "is not set",
    },
    {
      name: "gt",
      title: ">",
    },
    {
      name: "gte",
      title: ">=",
    },
    {
      name: "lt",
      title: "<",
    },
    {
      name: "lte",
      title: "<=",
    },
  ],
  time: [
    {
      name: "equals",
      title: "equals",
    },
    {
      name: "notEquals",
      title: "does not equal",
    },
    {
      name: "inDateRange",
      title: "in date range",
    },
    {
      name: "notInDateRange",
      title: "not in date range",
    },
    {
      name: "afterDate",
      title: "after date",
    },
    {
      name: "afterOrOnDate",
      title: "after or on date",
    },
    {
      name: "beforeDate",
      title: "before date",
    },
    {
      name: "beforeOrOnDate",
      title: "before or on date",
    },
  ],
};

export const suggestionOptions = [
  {
    name: "song_start by song_play_type",
    query: {
      limit: 99,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: config.APP_REPORT_DATE,
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.ep__song_play_type"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["song_start"],
        },
      ],
    },
  },
  {
    name: "song_* events count",
    query: {
      limit: 100,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: config.APP_REPORT_DATE,
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.event_name", "firebase.ama_app_id"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["song_start", "song_fail", "song_revive", "song_end", "song_result"],
        },
      ],
    },
  },
  {
    name: "me_* events count",
    query: {
      limit: 100,
      measures: ["firebase.event_count"],
      timeDimensions: [
        {
          dimension: config.APP_REPORT_DATE,
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.ama_app_id", "firebase.event_name"],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["me_start", "me_result"],
        },
      ],
    },
  },
  {
    name: "ad_impression by ad_format",
    query: {
      measures: ["firebase.user_count", "firebase.event_count"],
      timeDimensions: [
        {
          dimension: config.APP_REPORT_DATE,
          dateRange: "Last 7 days",
        },
      ],
      order: [["firebase.event_count", "desc"]],
      filters: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["ad_impression"],
        },
      ],
      dimensions: ["firebase.ama_app_id"],
    },
  },
  {
    name: "Top 10 version by user count",
    query: {
      limit: 10,
      measures: ["firebase.user_count"],
      timeDimensions: [
        {
          dimension: config.APP_REPORT_DATE,
          dateRange: "Last 7 days",
        },
      ],
      filters: [],
      order: [["firebase.event_count", "desc"]],
      dimensions: ["firebase.app_info__version"],
    },
  },
];

// url
export { PAGE_URL } from "./page-url";
