import { config } from "@src/config";
import { DATE_RANGE_VALUE } from "@src/config/query-builder";
import dayjs from "dayjs";

export const convertToDateRangeValue = (
  value: string,
  option?: {
    autoFormat?: boolean;
  },
) => {
  let startDate;
  let endDate;

  switch (value) {
    case DATE_RANGE_VALUE.TODAY:
      startDate = dayjs();
      endDate = dayjs();
      break;
    case DATE_RANGE_VALUE.YESTERDAY:
      startDate = dayjs().subtract(1, "day");
      endDate = dayjs().subtract(1, "day");
      break;
    case DATE_RANGE_VALUE.THIS_WEEK:
      startDate = dayjs().weekday(1); // Monday
      endDate = dayjs(); // Today
      break;
    case DATE_RANGE_VALUE.THIS_MONTH:
      startDate = dayjs().date(1); // First day of the month
      endDate = dayjs(); // Today
      break;
    case DATE_RANGE_VALUE.LAST_WEEK:
      startDate = dayjs().weekday(-6); // Last Monday
      endDate = dayjs().weekday(0); // Last Sunday
      break;
    case DATE_RANGE_VALUE.LAST_MONTH: {
      const lastMonth = dayjs().subtract(1, "month");
      const numDays = lastMonth.daysInMonth();
      startDate = dayjs(lastMonth).date(1); // First day of last month
      endDate = dayjs(lastMonth).date(numDays); // Last day of last month
      break;
    }
    case DATE_RANGE_VALUE.LAST_7_DAYS:
      startDate = dayjs().subtract(7, "days"); // Last 7 days
      endDate = dayjs(); // Today
      break;

    case DATE_RANGE_VALUE.LAST_14_DAYS:
      startDate = dayjs().subtract(14, "days"); // Last 14 days
      endDate = dayjs(); // Today
      break;

    case DATE_RANGE_VALUE.LAST_30_DAYS:
      startDate = dayjs().subtract(30, "days"); // Last 30 days
      endDate = dayjs(); // Today
      break;
    default:
      startDate = dayjs();
      endDate = dayjs();
  }

  return option?.autoFormat
    ? [dayjs(startDate).format(config.DATE_FORMAT), dayjs(endDate).format(config.DATE_FORMAT)]
    : [startDate, endDate];
};
