import { SEARCH_PARAMS_KEY } from "@src/constant/ab-testing/user-count";
import { ChartFormFieldsEnum, FilterFormFieldsEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import { URLParamsManager } from "../url-params-manager";
import { checkConfigChartForm, checkConfigFilterForm, checkTabsConfigParams } from "./validate-config-params";

const parsedParams = () => {
  const urlParamsManager = new URLParamsManager();
  const paramsConfig = urlParamsManager.getParam(SEARCH_PARAMS_KEY.CONFIG);
  try {
    if (!paramsConfig) return;
    const paramsConfigParsed = JSON.parse(atob(paramsConfig));

    const { filterForm, chartForm, tabsConfig } = paramsConfigParsed;

    checkConfigFilterForm(filterForm);
    checkConfigChartForm(chartForm);
    checkTabsConfigParams(tabsConfig);

    return paramsConfigParsed;
  } catch (error: any) {
    console.log("error :>> ", error);
    alert("Invalid config params!");
    urlParamsManager.deleteParam(SEARCH_PARAMS_KEY.CONFIG);
  }
};

export const getGroupFieldValue = (location: "filterForm" | "chartForm" | "tabsConfig") => {
  const paramsParsed = parsedParams();
  return paramsParsed?.[location];
};

export const getParamsFieldValue = (
  field: FilterFormFieldsEnum | ChartFormFieldsEnum | TabsViewParamsEnum,
  location: "filterForm" | "chartForm" | "tabsConfig",
) => {
  const paramsParsed = parsedParams();
  return paramsParsed?.[location]?.[field];
};
